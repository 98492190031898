import { useRedirect } from "@/hooks/useRedirect";
import { FormActions } from "@/store/reducers/form.reducer";
import { Card } from "@components/dynamic/Card";
import Button from "@components/ui/buttons/Button";
import { useAction } from "@hooks/useAction";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { Col, Form, FormInstance, Input, Row, Space } from "antd";
import { Rule } from "antd/lib/form";
import classNames from "classnames";
import { Check, NotePencil, X } from "phosphor-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Remark, useRemark } from "react-remark";

const RegexParser = require("regex-parser");

interface IProps {
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  form: FormInstance<any>;
  editInline?: boolean;

  formState: any;
  setFormState: any;
  onChange?: (value: any, variable: string) => void;
  visible?: boolean;
}

export const FieldTextarea: React.FC<IProps> = ({
  block,
  formState,
  setFormState,
  scope = [],
  form,
  editInline,
  onChange,
  visible,
}) => {
  const { checkRedirect } = useRedirect();
  const config: IScope[] = block?.config || [];
  const [reactContent, setMarkdownSource] = useRemark();

  const variable = config.find((e) => e.name === "variable")?.value || "";
  const placeholder = config.find((e) => e.name === "placeholder")?.value || "";
  const required = config.find((e) => e.name === "required")?.value === "1";
  const disabled = config.find((e) => e.name === "disabled")?.value === "1";
  const pattern = config.find((e) => e.name === "pattern")?.value;
  const validation_message = config.find(
    (e) => e.name === "validation_message"
  )?.value;

  const graphql = config.find((e) => e.name === "graphql")?.value;
  const expanded = config.find((e) => e.name === "expanded")?.value === "1";
  const expandable = config.find((e) => e.name === "expandable")?.value === "1";
  const rows = config.find((e) => e.name === "rows")?.value;
  const can_do = config.find((e) => e.name === "can_do")?.value === "1";
  const default_value = config.find((e) => e.name === "default")?.value || "";

  const auto_save = config.find((e) => e.name === "auto_save")?.value || "";
  const autoFocus = config.find((e) => e.name === "autoFocus")?.value === "1";
  const query = config.find((e) => e.name === "query")?.value;
  const output = config.find((e) => e.name === "output")?.value || "text";
  const timeout = config.find((e) => e.name === "timeout")?.value
    ? parseInt(
        config.find((e) => e.name === "timeout")?.value || ("0" as string)
      )
    : 1000;

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const dispatch = useDispatch();
  const { doMutateQraphQl, replaceFromScope } = useAction();

  const [state, setState] = useState({
    loading: false,
    isValid: false,
    editMode: false,
    value: block?.body || default_value || "",
    editedValue: block?.body || default_value || "",
  });

  useEffect(() => {
    setMarkdownSource(block?.body);
  }, []);

  const { rules } = useMemo(() => {
    const rules: Rule[] = [];

    if (pattern) {
      rules.push({
        pattern: new RegExp(RegexParser(pattern)),
        message: validation_message,
      });
    }
    if (required) {
      rules.push({
        required: required,
        message: `${block?.title || block?.label} is required.`,
      });
    }

    return { rules };
  }, [required, pattern]);

  useEffect(() => {
    form.setFieldsValue({ [variable]: default_value });
    setFormState((s: any) => ({ ...s, [variable]: default_value }));
    onChange && onChange(default_value, variable);
  }, []);

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        ref.current?.focus();
      }, 500);
    }
  }, [autoFocus]);

  const CardComponent = (
    <Card
      body={
        output === "markdown" ? <Remark>{state.value}</Remark> : state.value
      }
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      visible={visible}
      config={config}
      editBodyButton={
        can_do ? (
          <Button
            key="card-edit-action-edit-inline"
            // type="button"
            loading={state.loading}
            variant="primary"
            onClick={() => setState((s) => ({ ...s, editMode: true }))}
            icon={<NotePencil />}
          />
        ) : undefined
      }
    />
  );

  const save = () => {
    let query = "";

    if (required && !state.editedValue) return;

    if (pattern) {
      const expression = new RegExp(RegexParser(pattern));

      const isMatch = expression.test(state.editedValue);
      if (!isMatch) return;
    }

    query = replaceFromScope({
      scope: [{ name: variable, value: state.editedValue }],
      str: graphql + "",
      withQuate: false,
    });

    setState((s) => ({ ...s, loading: true }));

    doMutateQraphQl(query)
      .then(() => {
        checkRedirect(config);
        setState((s) => ({
          ...s,
          loading: false,
          editMode: false,
          value: s.editedValue,
        }));

        onChange && onChange(state.editedValue, variable);
      })
      .catch((e) => {
        setState((s) => ({ ...s, loading: false, editMode: false }));
      });
  };
  const cancel = () => {
    setState((s) => ({ ...s, editMode: false, editedValue: s.value }));
  };

  const Required = () => {
    return (
      required &&
      !state.editedValue && (
        <div className="ant-form-item-explain ">
          <div role="alert" className="ant-form-item-explain-error">
            {variable} is required.
          </div>
        </div>
      )
    );
  };
  const Pattern = () => {
    if (pattern) {
      const expression = new RegExp(RegexParser(pattern));

      const isMatch = expression.test(state.editedValue);

      return (
        !isMatch && (
          <div className="ant-form-item-explain ">
            <div role="alert" className="ant-form-item-explain-error">
              {validation_message}
            </div>
          </div>
        )
      );
    } else return null;
  };

  const ref = useRef<any>(null);

  const onFieldChange = (val: string) => {
    if (auto_save && auto_save === "on_change") {
      if (required && !val) return;
      if (timer) {
        clearTimeout(timer);
      }

      const newTimer = setTimeout(async () => {
        let q = "";

        if (pattern) {
          const expression = new RegExp(RegexParser(pattern));

          const isMatch = expression.test(val);
          if (!isMatch) return;
        }

        q = replaceFromScope({
          scope: [{ name: variable, value: val }],
          str: query + "",
          withQuate: false,
        });

        doMutateQraphQl(q)
          .then(() => {
            checkRedirect(config);
          })
          .catch((err) => {
            console.log(err);
          });
      }, timeout);
      setTimer(newTimer);
    }
  };

  const onFieldBlur = (val: string) => {
    if (auto_save && auto_save === "on_blur") {
      if (required && !val) return;
      let q = "";

      if (pattern) {
        const expression = new RegExp(RegexParser(pattern));

        const isMatch = expression.test(val);
        if (!isMatch) return;
      }

      q = replaceFromScope({
        scope: [{ name: variable, value: val }],
        str: query + "",
        withQuate: false,
      });

      doMutateQraphQl(q)
        .then(() => {
          checkRedirect(config);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  return editInline ? (
    state.editMode ? (
      <Card
        body={""}
        title={block?.title}
        expanded={expanded}
        classes={block?.classes}
        expandable={expandable}
        visible={visible}
        config={config}
      >
        <Row wrap={false} gutter={[16, 16]}>
          <Col flex="auto">
            {output === "text" && (
              <Input.TextArea
                rows={rows ? parseInt(rows) : undefined}
                placeholder={placeholder}
                disabled={disabled || state.loading}
                value={state.editedValue}
                onChange={(event) => {
                  setState((s) => ({ ...s, editedValue: event.target.value }));
                }}
                ref={ref}
                onFocus={() => {
                  dispatch(FormActions.saveInput({ lastInput: ref.current }));
                }}
              />
            )}
            {(output === "html" || output === "markdown") && (
              <ReactQuill
                modules={modules}
                formats={formats}
                value={state.editedValue}
                onChange={(value) => {
                  setState((s) => ({ ...s, editedValue: value }));
                }}
                theme="snow"
                placeholder={placeholder}
                onFocus={() => {
                  dispatch(FormActions.saveInput({ lastInput: ref.current }));
                }}
              />
            )}
          </Col>
          <Col flex="none">
            <Space direction="horizontal" style={{}}>
              <Button
                variant="primary"
                type="primary"
                icon={<Check />}
                // width={10}
                onClick={save}
                loading={state.loading}
              ></Button>
              <Button
                variant="danger"
                type="primary"
                icon={<X />}
                // width={10}
                disabled={state.loading}
                onClick={cancel}
              ></Button>
            </Space>
          </Col>
        </Row>

        {Required()}
        {Pattern()}
      </Card>
    ) : (
      CardComponent
    )
  ) : (
    <Form.Item
      label={block?.title || block?.label}
      name={variable}
      rules={rules}
      className={classNames(visible === false && "d-none")}
    >
      {output === "text" && (
        <Input.TextArea
          placeholder={placeholder}
          rows={rows ? parseInt(rows) : undefined}
          onChange={(event) => {
            form.setFieldsValue({ [variable]: event.target.value });
            setFormState((s: any) => ({
              ...s,
              [variable]: event.target.value,
            }));
            onFieldChange?.(event.target.value);
            onChange && onChange(event.target.value, variable);
          }}
          onBlur={({ target: { value } }) => {
            onFieldBlur?.(value);
          }}
          ref={ref}
          onFocus={() => {
            dispatch(FormActions.saveInput({ lastInput: ref.current }));
          }}
          disabled={disabled}
        />
      )}
      {(output === "html" || output === "markdown") && (
        <ReactQuill
          modules={modules}
          formats={formats}
          value={state.editedValue}
          onChange={(value) => {
            form.setFieldsValue({ [variable]: value });
            setFormState((s: any) => ({
              ...s,
              [variable]: value,
            }));
            onFieldChange?.(value);
            setState((s) => ({ ...s, editedValue: value }));
            onChange && onChange(value, variable);
          }}
          theme="snow"
          placeholder={placeholder}
          onFocus={() => {
            dispatch(FormActions.saveInput({ lastInput: ref.current }));
          }}
        />
      )}
    </Form.Item>
  );
};
