import AuthLogo from "@/components/AuthLogo";
import { SIGNIN } from "@/queries";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Captcha } from "@components/auth/Captcha";
import Button from "@components/ui/buttons/Button";
import { useServiceWorker } from "@hooks/useServiceWorker";
import { AuthActions, authSelector } from "@store/reducers/auth.reducer";
import { themeSelector } from "@store/reducers/theme.reducer";
import { Checkbox, Col, Form, Input, Row } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

type Props = {};

export const Signin: React.FC<Props> = ({}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { authConfig } = useSelector(authSelector);
  const [login, { data, loading, error }] = useMutation(SIGNIN);
  const navigate = useNavigate();
  const { setToken } = useServiceWorker();

  const theme = useSelector(themeSelector).theme;

  // const getTokenFunc = async () => {
  //   const token = await getToken();
  //   dispatch(Actions.login(token, {}, false));
  // };

  const onFinish = (values: any) => {
    const remember = values.remember;

    login({ variables: values })
      .then((data) => {
        if (!data.errors) {
          if (!remember) {
            setToken(data.data.login);
          }
          dispatch(
            AuthActions.login({
              accessToken: data.data.login,
              user: {},
              remember,
            })
          );
          const unauthPage = localStorage.getItem("unauthPage");

          if (unauthPage) {
            navigate(unauthPage);
            localStorage.removeItem("unauthPage");
          }
        }
      })
      .catch((e) => {});
  };

  useEffect(() => {
    document.title = t("login");
  });

  return (
    <div className="auth-form">
      <div
        className="logo text-center mb-4 w-100"
        style={{ maxWidth: "350px" }}
      >
        <AuthLogo />
      </div>

      <>
        {authConfig?.login?.enabled ? (
          <Form
            name="normal_login"
            className="login-form w-100"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            style={{ maxWidth: "350px" }}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: t("thisFieldRequired", { name: "username" }),
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={t("username")}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t("thisFieldRequired", { name: "password" }),
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder={t("password")}
              />
            </Form.Item>
            <Form.Item>
              <Row justify="space-between" align="middle">
                <Col className="">
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>{t("rememberMe")}</Checkbox>
                  </Form.Item>
                </Col>
                <Col className="">
                  <Link className="login-form-forgot" to="/forget-password">
                    {t("forgotPassword")}
                  </Link>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item>
              <Button
                // type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={loading}
                loading={loading}
                block
              >
                {t("login")}
              </Button>
            </Form.Item>
            {authConfig?.signup?.enabled && (
              <p className="text-center">
                {t("dontHaveAaccount")}{" "}
                <Link to={"/signup"}>{t("createAccount")}</Link>
              </p>
            )}

            <p className="text-center">
              <Link to={"/portal/page/contactus"}>
                {t("haveAProblemClickHere")}
              </Link>
            </p>

            {/* <Divider className="mt-2 mb-2" plain>
                {t("loginWith")}
              </Divider>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <GoogleButton buttonText={t("google")} />
                </Col>
                <Col span={12}>
                  <FacebookButton buttonText={t("facebook")} />
                </Col>
              </Row> */}

            <Captcha />
          </Form>
        ) : (
          <span>{authConfig?.login?.message}</span>
        )}
      </>
    </div>
  );
};
