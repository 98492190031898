import { Action } from "@components/dynamic/Action";
import { Icon } from "@components/dynamic/Icon";
import { useAction } from "@hooks/useAction";
import { IAction } from "@interfaces/action.interface";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { Avatar, Badge, Col, FormInstance, List, Row, Space } from "antd";
import classNames from "classnames";
import moment from "moment";
import { BellSimple } from "phosphor-react";
import React from "react";

interface IProps {
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  form: FormInstance<any>;

  formState: any;
  setFormState: any;
  editInline?: boolean;

  onChange?: (value: any, variable: string) => void;
  visible?: boolean;
}

export const Notification: React.FC<IProps> = ({
  block,
  scope = [],
  form,
  setFormState,
  formState,
  onChange,
  editInline = false,
  visible,
  ...props
}) => {
  const { clickAction } = useAction();

  const config: IScope[] = block?.config || [];
  const icon = config.find((e) => e.name === "icon")?.value || "";
  const image = config.find((e) => e.name === "image")?.value || "";
  const seen = config.find((e) => e.name === "seen")?.value === "1";
  const read = config.find((e) => e.name === "read")?.value === "1";
  const date = config.find((e) => e.name === "date")?.value;
  const onClickAction = block?.onClickAction as IAction | undefined;
  const actions = (block?.actions || []) as IAction[];

  const clickActionEvent = () => {
    if (onClickAction) {
      clickAction({ action: onClickAction, scope: scope, item: undefined });
    }
  };

  return (
    <React.Fragment>
      <List.Item
        className={classNames(
          "notification-list-item",
          visible === false && "d-none",
          onClickAction && "pointer",
          !seen && !read && "not-seen-not-read",
          seen && !read && "seen-not-read",
          seen && read && "seen-read"
        )}
        extra={!seen && !read && <Badge dot color={"blue"}></Badge>}
        onClick={clickActionEvent}
      >
        <List.Item.Meta
          avatar={
            <div className="mx-1">
              {!!image ? (
                <Avatar src={image} className="mx-0" />
              ) : !!icon ? (
                <Icon name={icon + ""} className="mx-0" />
              ) : (
                <Avatar icon={<BellSimple />} className="mx-0" />
              )}
            </div>
          }
          title={<span>{block?.title}</span>}
          description={
            <Row>
              <Col span={24}>
                <span>{block?.body}</span>
              </Col>
              {!!date && (
                <Col span={24}>
                  <small>{moment(parseInt(date + "000")).fromNow()}</small>
                </Col>
              )}

              {actions.length > 0 && (
                <Col span={24} style={{ marginTop: 10, marginBottom: 10 }}>
                  {actions.map((action, index) => {
                    return (
                      <Space
                        key={`action-notification-${index}`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Action
                          style={{ marginRight: "5px" }}
                          // type="general"
                          action={action}
                          item={undefined}
                        />
                      </Space>
                    );
                  })}
                </Col>
              )}
            </Row>
          }
        />
      </List.Item>
    </React.Fragment>
  );
};
