import { useAction } from "@/hooks/useAction";
import { IBlock } from "@/interfaces/block.interface";
import { IScope } from "@/interfaces/scope.interface";
import { Col, Input, Row, Select, Space } from "antd";
import debounce from "lodash/debounce";
import { Plus, X } from "phosphor-react";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import short from "short-uuid";
import Button from "../ui/buttons/Button";
import { Card } from "./Card";
import { Loading } from "./Loading";

interface IInvoiceItemEditor {
  block: IBlock;
  formState: any;
  visible?: boolean;
  setFormState: any;
}

interface InvoiceRow {
  product: IOption | string;
  price: number;
  qty: number;
  tax: IOption | string;
  total: number;
  description: string;
  id: string;
  fetchProducts: boolean;
  productQuery: string;
  productsOptions: IOption[];
  fetchTaxs: boolean;
  taxQuery: string;
  taxsOptions: IOption[];
  productOption?: IOption;
  taxOption?: IOption;
}

interface IOption {
  label: string;
  value: string;
  id?: string;
  option?: any;
}

export const InvoiceItemEditor: FC<IInvoiceItemEditor> = ({
  block,
  visible = true,
  formState,
  setFormState,
}) => {
  const defaultOption: InvoiceRow = {
    product: "",
    price: 0,
    qty: 1,
    tax: "",
    total: 0,
    description: "",
    id: short.generate(),
    fetchProducts: false,
    productQuery: "",
    productsOptions: [],
    fetchTaxs: false,
    taxQuery: "",
    taxsOptions: [],
  };
  const config: IScope[] = block?.config || [];

  const { doQuery } = useAction();

  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [taxs, setTaxes] = useState<IOption[]>([]);
  const [rows, setRows] = useState<InvoiceRow[]>([defaultOption]);

  const products_query = config.find((e) => e.name === "products_query")?.value;
  const products_accessor = config.find(
    (e) => e.name === "products_accessor"
  )?.value;
  const items_query = config.find((e) => e.name === "items_query")?.value;
  const items_accessor = config.find((e) => e.name === "items_accessor")?.value;
  const tax_query = config.find((e) => e.name === "taxes_query")?.value;
  const tax_accessor = config.find((e) => e.name === "taxes_accessor")?.value;
  const variable = config.find((e) => e.name === "variable")?.value;
  const enable_editing =
    config.find((e) => e.name === "enable_editing")?.value === "1";

  const getData = () => {
    if (products_query) {
      doQuery({
        query: products_query,
        scope: [{ name: "product_name", value: "" }],
        withQuate: false,
      }).then((data: any) => {
        if (data?.data) {
          setOptions(
            data?.data[products_accessor || ""].options.map((one: any) => ({
              label: one.title,
              value: one.name,
              option: one,
            })) || []
          );
        }
      });
    }
  };

  const getTaxes = () => {
    if (tax_query) {
      doQuery({
        query: tax_query,
      }).then((data: any) => {
        if (data?.data) {
          setTaxes(
            data?.data[tax_accessor || ""].map((one: any) => ({
              label: one.title,
              value: one.name,
              option: one,
            })) || []
          );
        }
      });
    }
  };

  useEffect(() => {
    const newRows: InvoiceRow[] = JSON.parse(JSON.stringify(rows));
    setFormState((s: any) => ({
      ...s,
      [variable as string]: JSON.stringify(
        newRows.map((one) => {
          return one;
        })
      ),
    }));
  }, [rows, setFormState, variable]);

  const searchProducts = useMemo(() => {
    if (!products_query) return;
    const loadOptions = (val: string, one: InvoiceRow) => {
      if (val === "") {
        return;
      }
      setRows((s) =>
        s.map((e) => {
          if (e.id === one.id) {
            e.fetchProducts = true;
            e.productQuery = val;
          }
          return e;
        })
      );
      doQuery({
        query: products_query,
        scope: [{ name: "product_name", value: val.toString() }],
        withQuate: false,
      })
        .then((data: any) => {
          if (data?.data) {
            setRows((s) =>
              s.map((e) => {
                if (e.id === one.id) {
                  e.productsOptions =
                    data?.data[products_accessor || ""].options.map(
                      (one: any) => ({
                        label: one.title,
                        value: one.name,
                      })
                    ) || [];
                }
                return e;
              })
            );
          }
        })
        .finally(() => {
          setRows((s) =>
            s.map((e) => {
              if (e.id === one.id) {
                e.fetchProducts = false;
              }
              return e;
            })
          );
        });
    };

    return debounce(loadOptions, 1000);
  }, [products_query, rows, products_accessor]);

  const getItems = () => {
    if (items_query) {
      setLoading(true);
      doQuery({
        query: items_query,
      })
        .then((data: any) => {
          if (data?.data) {
            setRows([...data?.data[items_accessor || ""], defaultOption] || []);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getData();
  }, [products_query]);

  useEffect(() => {
    getTaxes();
  }, [tax_query]);

  useEffect(() => {
    getItems();
  }, [items_query]);

  const calcTotal = () => {
    return rows.reduce((a, b) => {
      return a + b.price * b.qty;
    }, 0);
  };

  const calcTax = () => {
    return rows.reduce((a, b) => {
      return (
        a +
        (b.taxOption
          ? b.taxOption?.option?.type === "percent"
            ? (b.total * b.taxOption?.option?.value) / 100
            : b.taxOption?.option?.value
          : 0)
      );
    }, 0);
  };

  const calcTotalWithTax = () => {
    return rows.reduce((a, b) => {
      return a + b.total;
    }, 0);
  };

  const updateRows = useCallback(() => {
    setRows((s) =>
      s.map((one) => {
        one.total = one.price * one.qty;
        if (one.taxOption) {
          if (one.taxOption?.option?.type === "fixed") {
            one.total = one.total + one.taxOption?.option?.value;
          }
          if (one.taxOption?.option?.type === "percent") {
            one.total =
              one.total + (one.total * one.taxOption?.option?.value) / 100;
          }
        }
        return one;
      })
    );
  }, [rows]);

  return visible ? (
    <>
      <Card title="" body="">
        {loading && <Loading />}
        {!loading && (
          <Row
            style={{
              gap: "10px",
              justifyContent: "space-between",
            }}
            align="middle"
          >
            <Col>
              <p>{t("product")}</p>
            </Col>
            <Col>
              <p>{t("price")}</p>
            </Col>
            <Col>
              <p>{t("quantity")}</p>
            </Col>
            <Col>
              <p>{t("tax")}</p>
            </Col>
            <Col>
              <p>{t("total")}</p>
            </Col>
            <Col>
              <p>{t("description")}</p>
            </Col>
            <Col style={{ minWidth: 65 }} />
          </Row>
        )}
        {!loading &&
          rows.map((one, index) => (
            <Row
              key={index}
              style={{ marginBottom: "10px", gap: "10px" }}
              align="middle"
            >
              <Select
                disabled={!enable_editing}
                className="flex-1"
                showSearch
                onSearch={(val) => searchProducts?.(val, one)}
                value={
                  typeof one.product !== "string"
                    ? one.product.id
                    : one.product || undefined
                }
                filterOption={false}
                onBlur={() => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.fetchProducts = false;
                        e.productQuery = "";
                      }
                      return e;
                    })
                  );
                }}
                options={one.productQuery ? one.productsOptions : options}
                loading={one.fetchProducts}
                onChange={(value) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        const dt = options || one.productsOptions;
                        e.productOption = dt.find((e) => e.value === value);
                        e.product = value;
                        e.price =
                          e.productOption?.option?.object.sale_price || 0;
                      }
                      return e;
                    })
                  );
                  updateRows();
                }}
                placeholder={t("selectProduct")}
              />
              <Input
                disabled={!enable_editing}
                className="flex-1"
                placeholder={t("price")}
                type="number"
                value={one.price}
                onChange={(event) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.price = Number(event.target.value);
                      }
                      return e;
                    })
                  );
                  updateRows();
                }}
              />
              <Input
                disabled={!enable_editing}
                className="flex-1"
                placeholder={t("quantity")}
                type="number"
                value={one.qty}
                onChange={(event) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.qty = Number(event.target.value);
                      }
                      return e;
                    })
                  );
                  updateRows();
                }}
              />
              <Select
                disabled={!enable_editing}
                className="flex-1"
                value={
                  typeof one.tax !== "string"
                    ? one?.tax?.id
                    : one.tax || undefined
                }
                filterOption={false}
                onBlur={() => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.fetchTaxs = false;
                        e.taxQuery = "";
                      }
                      return e;
                    })
                  );
                }}
                options={one.taxQuery ? one.taxsOptions : taxs}
                loading={one.fetchTaxs}
                onChange={(value) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.taxOption = taxs.find((e) => e.value === value);
                        e.tax = value;
                      }
                      return e;
                    })
                  );
                  updateRows();
                }}
                placeholder={t("selectTax")}
              />
              <Input
                disabled
                className="flex-1"
                placeholder={t("total")}
                type="number"
                value={one.total}
                onChange={(event) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.total = Number(event.target.value);
                      }
                      return e;
                    })
                  );
                }}
              />
              <Input
                disabled={!enable_editing}
                className="flex-1"
                placeholder={t("description")}
                value={one.description}
                onChange={(event) => {
                  setRows(
                    rows.map((e) => {
                      if (e.id === one.id) {
                        e.description = event.target.value;
                      }
                      return e;
                    })
                  );
                }}
              />

              {enable_editing && (
                <>
                  {index === rows.length - 1 ? (
                    <Button
                      onClick={() => {
                        setRows((s) => [...s, defaultOption]);
                      }}
                      variant="primary"
                      type="link"
                      size="small"
                    >
                      <Plus />
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        setRows(rows.filter((e) => e.id !== one.id));
                      }}
                      variant="danger"
                      type="link"
                      size="small"
                    >
                      <X />
                    </Button>
                  )}
                </>
              )}
            </Row>
          ))}

        <Space direction="vertical" className="invoice-item-footer">
          <div className="d-flex invoice-total-result">
            <p>{t("total")}</p>
            <span>{isNaN(calcTotal()) ? 0 : calcTotal()}</span>
          </div>
          <div className="d-flex invoice-total-result">
            <p>{t("taxTotal")}</p>
            <span>{isNaN(calcTax()) ? 0 : calcTax().toFixed(4)}</span>
          </div>
          <div className="d-flex invoice-total-result">
            <p>{t("totalWithTax")}</p>
            <span>
              {isNaN(calcTotalWithTax()) ? 0 : calcTotalWithTax().toFixed(4)}
            </span>
          </div>
        </Space>
      </Card>
    </>
  ) : (
    <></>
  );
};
