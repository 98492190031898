import { useAction } from "@/hooks/useAction";
import { useRedirect } from "@/hooks/useRedirect";
import { IScope } from "@interfaces/scope.interface";
import { DatePicker, Form, FormInstance } from "antd";
import { Rule } from "antd/lib/form";
import classNames from "classnames";
import moment from "moment";
import React, { useMemo } from "react";

const RegexParser = require("regex-parser");
const { RangePicker } = DatePicker;

interface IProps {
  block: any;
  scope: IScope[];
  loading: boolean;
  form: FormInstance<any>;

  formState: any;
  setFormState: any;
  onChange?: (value: any, variable: string) => void;
  visible?: boolean;
}

export const FieldDateRangePicker: React.FC<IProps> = ({
  block,
  setFormState,
  formState,
  scope = [],
  form,
  onChange,
  visible,
}) => {
  const { doMutateQraphQl, replaceFromScope } = useAction();
  const { checkRedirect } = useRedirect();

  const variable = block?.config?.find(
    (e: any) => e.name === "variable"
  )?.value;
  const picker = block?.config?.find((e: any) => e.name === "picker")?.value;
  const min = block?.config?.find((e: any) => e.name === "min")?.value;
  const max = block?.config?.find((e: any) => e.name === "max")?.value;

  const disabled =
    block?.config?.find((e: any) => e.name === "disabled")?.value === "1";
  const format = block?.config?.find((e: any) => e.name === "format");
  const placeholder =
    block?.config?.find((e: any) => e.name === "placeholder")?.value || "";
  const required =
    block?.config?.find((e: any) => e.name === "required")?.value === "1";
  const allowClear =
    block?.config?.find((e: any) => e.name === "allowClear")?.value === "1";
  const pattern = block?.config?.find((e: any) => e.name === "pattern")?.value;
  const validation_message = block?.config?.find(
    (e: any) => e.name === "validation_message"
  )?.value;

  const auto_save =
    block?.config?.find((e: any) => e.name === "auto_save")?.value || "";
  const query = block?.config?.find((e: any) => e.name === "query")?.value;

  const { rules } = useMemo(() => {
    const rules: Rule[] = [];

    if (pattern) {
      rules.push({
        pattern: new RegExp(RegexParser(pattern)),
        message: validation_message,
      });
    }
    if (required) {
      rules.push({
        required: required,
        message: `${block?.title || block?.label} is required.`,
      });
    }

    return { rules };
  }, [required, pattern]);

  const onFieldChange = (val: any) => {
    if (auto_save && auto_save === "on_change") {
      let q = "";

      if (pattern) {
        const expression = new RegExp(RegexParser(pattern));

        const isMatch = expression.test(val);
        if (!isMatch) return;
      }

      q = replaceFromScope({
        scope: [{ name: variable, value: val }],
        str: query + "",
        withQuate: false,
      });

      doMutateQraphQl(q)
        .then(() => {
          checkRedirect(block.config);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Form.Item
      label={block?.title || block?.label}
      name={variable}
      rules={rules}
      className={classNames(visible === false && "d-none")}
    >
      <RangePicker
        ranges={{
          Today: [moment(), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
        }}
        showTime
        format={format}
        style={{ width: "100%" }}
        placeholder={placeholder}
        disabled={disabled}
        picker={picker}
        allowClear={allowClear}
        onChange={(value) => {
          const result = value
            ? [
                value[0]?.format("YYYY-MM-DD HH:mm:ss"),
                value[1]?.format("YYYY-MM-DD HH:mm:ss"),
              ]
            : [];
          form.setFieldsValue({ [variable]: value });
          setFormState((s: any) => ({ ...s, [variable]: result }));
          if (result) {
            onFieldChange(result);
          }
          onChange && onChange(result, variable);
        }}
      />
    </Form.Item>
  );
};
