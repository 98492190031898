import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { AuthActions } from "@store/reducers/auth.reducer";
import { Checkbox, Col, Divider, Form, Input, Row } from "antd";
import { Phone, UserFocus } from "phosphor-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Captcha } from "@components/auth/Captcha";
import { FacebookButton } from "@components/auth/FacebookButton";
import { GoogleButton } from "@components/auth/GoogleButton";
import Button from "@components/ui/buttons/Button";
import { useUtiles } from "@hooks/useUtiles";
import { SIGNUP } from "@/queries";

import { themeSelector } from "@store/reducers/theme.reducer";
import AuthLogo from "@/components/AuthLogo";

type Props = {};
export const Signup: React.FC<Props> = ({}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useSelector(themeSelector).theme;
  const [signup, { data, loading, error }] = useMutation(SIGNUP);
  const { consoleLog } = useUtiles();
  const onFinish = (values: any) => {
    signup({ variables: values })
      .then((data) => {
        dispatch(
          AuthActions.signup({ accessToken: data.data.signup, user: {} })
        );
      })
      .catch((e) => {
        consoleLog("error on signup : ", { e });
      });
  };
  return (
    <div className="auth-form">
      <div
        className="logo text-center mb-4 w-100"
        style={{ maxWidth: "350px" }}
      >
        <AuthLogo />
      </div>

      <Form
        name="normal_login"
        className="login-form w-100"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        style={{ maxWidth: "350px" }}
      >
        <Form.Item name="firstname" rules={[]}>
          <Input
            prefix={<UserFocus className="site-form-item-icon" />}
            placeholder={t("firstname")}
          />
        </Form.Item>

        <Form.Item name="lastname" rules={[]}>
          <Input
            prefix={<UserFocus className="site-form-item-icon" />}
            placeholder={t("lastname")}
          />
        </Form.Item>

        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: t("thisFieldRequired", { name: "username" }),
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={t("username")}
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: t("thisFieldEmail", { name: "email" }),
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={t("email")}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: t("thisFieldRequired", { name: "phone" }),
            },
            {
              pattern:
                /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g,
              message: t("thisFieldPhone", { name: "phone" }),
            },
          ]}
        >
          <Input
            prefix={<Phone className="site-form-item-icon" />}
            placeholder={t("phone")}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: t("thisFieldRequired", { name: "password" }),
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={t("password")}
          />
        </Form.Item>
        <Form.Item>
          <Row justify="space-between" align="middle">
            <Col className="">
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>{t("rememberMe")}</Checkbox>
              </Form.Item>
            </Col>
            <Col className="">
              <Link className="login-form-forgot" to="/forget-password">
                {t("forgotPassword")}
              </Link>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item>
          <Button
            // type="secondary"
            htmlType="submit"
            className="login-form-button"
            disabled={loading}
            loading={loading}
            block
          >
            {t("signup")}
          </Button>
        </Form.Item>
        <p className="text-center">
          {t("ifYouHaveAccount")} <Link to={"/signin"}>{t("login")}</Link>
        </p>

        <Divider className="mt-2 mb-2" plain>
          {t("signupWith")}
        </Divider>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <GoogleButton buttonText={t("google")} />
          </Col>
          <Col span={12}>
            <FacebookButton buttonText={t("facebook")} />
          </Col>
        </Row>

        <Captcha />
      </Form>
    </div>
  );
};
