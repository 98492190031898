import { Table as AntTable, TablePaginationConfig, TableProps } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue, TableCurrentDataSource } from "antd/lib/table/interface";
import update from "immutability-helper";
import React, { useCallback, useRef } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { config } from "@/config";
import { useTranslation } from "react-i18next";

// const EditableContext = React.createContext<FormInstance<any> | null>(null);

// interface EditableRowProps {
//   index: number;
// }

// const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
//   const [form] = Form.useForm();
//   return (
//     <Form form={form} component={false}>
//       <EditableContext.Provider value={form}>
//         <tr {...props} />
//       </EditableContext.Provider>
//     </Form>
//   );
// };

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: any;
  record: any;
  handleSave: (record: any) => void;
}

// const EditableCell: React.FC<EditableCellProps> = ({
//   title,
//   editable,
//   children,
//   dataIndex,
//   record,
//   handleSave,
//   ...restProps
// }) => {
//   const [editing, setEditing] = useState(false);
//   const inputRef = useRef<Input>(null);
//   const form = useContext(EditableContext)!;

//   useEffect(() => {
//     if (editing) {
//       inputRef.current!.focus();
//     }
//   }, [editing]);

//   const toggleEdit = () => {
//     setEditing(!editing);
//     form.setFieldsValue({ [dataIndex]: record[dataIndex] });
//   };

//   const save = async () => {
//     try {
//       const values = await form.validateFields();

//       toggleEdit();
//       handleSave({ ...record, ...values });
//     } catch (errInfo) {

//     }
//   };

//   let childNode = children;

//   if (editable) {
//     childNode = editing ? (
//       <Form.Item
//         style={{ margin: 0 }}
//         name={dataIndex + ""}
//         rules={[
//           {
//             required: true,
//             message: `${title} is required.`,
//           },
//         ]}
//       >
//         <Input ref={inputRef} onPressEnter={save} onBlur={save} />
//       </Form.Item>
//     ) : (
//       <div
//         className="editable-cell-value-wrap"
//         style={{ paddingRight: 24 }}
//         onClick={toggleEdit}
//       >
//         {children}
//       </div>
//     );
//   }

//   return <td {...restProps}>{childNode}</td>;
// };

const Cell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  // const [editing, setEditing] = useState(false);
  // const inputRef = useRef<Input>(null);
  // const form = useContext(EditableContext)!;

  // useEffect(() => {
  //   if (editing) {
  //     inputRef.current!.focus();
  //   }
  // }, [editing]);

  // const toggleEdit = () => {
  //   setEditing(!editing);
  //   form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  // };

  // const save = async () => {
  //   try {
  //     const values = await form.validateFields();

  //     toggleEdit();
  //     handleSave({ ...record, ...values });
  //   } catch (errInfo) {

  //   }
  // };

  let childNode = children;

  // if (editable) {
  //   childNode = editing ? (
  //     <Form.Item
  //       style={{ margin: 0 }}
  //       name={dataIndex + ""}
  //       rules={[
  //         {
  //           required: true,
  //           message: `${title} is required.`,
  //         },
  //       ]}
  //     >
  //       <Input ref={inputRef} onPressEnter={save} onBlur={save} />
  //     </Form.Item>
  //   ) : (
  //     <div
  //       className="editable-cell-value-wrap"
  //       style={{ paddingRight: 24 }}
  //       onClick={toggleEdit}
  //     >
  //       {children}
  //     </div>
  //   );
  // }

  return <td {...restProps}>{childNode}</td>;
};

const type = "DraggableBodyRow";

export interface CustomTableProps {
  columns: ColumnsType<any>;
  data: any[];
  header?: React.FC<any>;
  loading?: boolean;
  pagination?: boolean;
  dragDrop?: boolean;
  hideSelectAll?: boolean;

  pageSize?: number;
  total?: number;
  page?: number;
  selectedRowKeys?: string[];
  rowKey?: string;
  footer?: any;

  allTotal?: number;
  totalSelected?: number;

  changePagination?: (page: number, pageSize: number, total: number) => void;
  getAction?: (
    action: "sort" | "dragDrop" | "select" | "filter" | "paginate" | "clickRow",
    result:
      | { page: number; pageSize: number; total: number }
      | {
          field: string;
          order: "descend" | "ascend";
        }
      | {
          current: number;
          pageSize: number;
          total: number;
        }
      | Record<string, FilterValue | null>
      | any[]
      | number // on click its the index number
  ) => void;
  hideSelection?: boolean;
}

export const Table: React.FC<CustomTableProps & TableProps<any>> = ({
  columns,
  data,
  header,
  loading,
  changePagination,
  pageSize = config.pageSize,
  total = 10,
  page = 1,
  getAction,
  dragDrop,
  pagination = true,
  selectedRowKeys = [],
  rowKey,
  hideSelectAll = false,
  footer,
  allTotal = 0,
  totalSelected = 0,
  hideSelection = false,
  ...props
}) => {
  const { t } = useTranslation();

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      getAction && getAction("select", selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
      // name: record.name,
    }),
  };

  const DraggableBodyRow = ({
    index,
    moveRow,
    className,
    style,
    ...restProps
  }: {
    index: number;
    moveRow: any;
    className: string;
    style: any;
  }) => {
    const ref = useRef(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor: any) => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName:
            dragIndex < index ? " drop-over-downward" : " drop-over-upward",
        };
      },
      drop: (item: any) => {
        moveRow(item.index, index);
      },
    });
    const [, drag] = useDrag({
      type,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));

    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ""}`}
        style={{ cursor: "move", ...style }}
        {...restProps}
        onClick={() => {
          getAction && getAction("clickRow", index);
        }}
      />
    );
  };

  const BodyRow = ({
    index,
    className,
    style,
    moveRow,
    ...restProps
  }: {
    index: number;
    className: string;
    moveRow: any;
    style: any;
  }) => {
    return (
      <tr
        style={{ ...style }}
        {...restProps}
        onClick={() => {
          getAction && getAction("clickRow", index);
        }}
      />
    );
  };

  const onChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any,
    extra: TableCurrentDataSource<any>
  ) => {
    if (extra.action === "sort") {
      getAction &&
        getAction("sort", {
          field: sorter?.field,
          order: sorter?.order,
        });
    }

    if (extra.action === "filter") {
      getAction && getAction("filter", filters);
    }
    if (extra.action === "paginate") {
      getAction &&
        getAction("paginate", {
          current: pagination.current || 1,
          pageSize: pagination.pageSize || config.pageSize,
          total: pagination.total || 0,
        });
      if (changePagination) {
        changePagination(
          pagination.current || 1,
          pagination.pageSize || config.pageSize,
          pagination.total || 0
        );
      }
    }
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = data[dragIndex];

      getAction &&
        getAction(
          "dragDrop",
          update(data, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragRow],
            ],
          })
        );
    },
    [data]
  );

  function itemRender(current: any, type: any, originalElement: any) {
    if (type === "prev") {
      return <a>{t("previous")}</a>;
    }
    if (type === "next") {
      return <a>{t("next")}</a>;
    }
    return originalElement;
  }

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <AntTable
          loading={loading}
          {...(hideSelection
            ? {}
            : {
                rowSelection: {
                  type: "checkbox",
                  defaultSelectedRowKeys: selectedRowKeys,
                  hideSelectAll,
                  ...rowSelection,
                },
              })}
          // components={{
          //   body: {
          //     row: EditableRow,
          //     cell: EditableCell,
          //   },
          // }}
          components={
            dragDrop
              ? {
                  body: {
                    row: DraggableBodyRow,
                  },
                }
              : {
                  body: {
                    row: BodyRow,
                    // cell : (e : any) => <Cell {...e} />
                  },
                }
          }
          onRow={(record, index) => ({
            tabIndex: index,
            index,
            moveRow,
          })}
          showHeader={true}
          // tableLayout={'fixed'}
          onChange={onChange}
          dataSource={data}
          columns={columns}
          rowKey={(data) => {
            return rowKey ? data[rowKey] : data.id;
          }}
          scroll={{ x: 1 }}
          // sticky
          title={header}
          pagination={
            pagination
              ? {
                  total: total,
                  current: page,
                  pageSize: pageSize,

                  position: ["bottomCenter"],
                  itemRender: itemRender,
                  showSizeChanger: false,

                  // showTotal : (total, range) => `${range[0]}-${range[1]} of ${total} filtered items , ${totalSelected} total selected, total is ${allTotal} `
                }
              : pagination
          }
          {...props}
        />
        {!!pagination && (
          <span className="text-center d-block table-total mb-1">
            {t("filtered_items_selected_items_total", {
              filtered: total,
              selected: totalSelected,
              total: allTotal,
            })}
          </span>
        )}
      </DndProvider>
    </>
  );
};
