import AuthLogo from "@/components/AuthLogo";
import { FORGET_PASSWORD } from "@/queries";
import { authSelector } from "@/store/reducers/auth.reducer";
import { UserOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import Button from "@components/ui/buttons/Button";
import { useUtiles } from "@hooks/useUtiles";
import { themeSelector } from "@store/reducers/theme.reducer";
import { Form, Input } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

type Props = {};
export const ForgetPassword: React.FC<Props> = ({}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useSelector(themeSelector).theme;
  const { consoleLog } = useUtiles();
  const { authConfig } = useSelector(authSelector);
  const [forget, { data, loading, error }] = useMutation(FORGET_PASSWORD);

  const onFinish = (values: any) => {
    forget({ variables: values })
      .then((data) => {
        navigate("/signin");
      })
      .catch((e) => {
        consoleLog("error on forget password", { e });
      });
  };

  useEffect(() => {
    document.title = t("forgotPassword");
  });

  return (
    <div className="auth-form">
      <div
        className="logo text-center mb-4 w-100"
        style={{ maxWidth: "350px" }}
      >
        <AuthLogo />
      </div>

      <Form
        name="normal_login"
        className="login-form w-100"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        style={{ maxWidth: "350px" }}
        layout="vertical"
      >
        <Form.Item
          name="username"
          label={t("pleaseEnterUsername")}
          rules={[
            {
              required: true,
              message: t("thisFieldRequired", { name: "username" }),
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={t("username")}
          />
        </Form.Item>

        <Form.Item>
          <Button
            // type="secondary"
            htmlType="submit"
            className="login-form-button"
            disabled={loading}
            loading={loading}
            block
          >
            {t("sendLink")}
          </Button>
        </Form.Item>
        {authConfig?.signup?.enabled && (
          <p className="text-center">
            {t("dontHaveAaccount")}{" "}
            <Link to={"/signup"}>{t("createAccount")}</Link>
          </p>
        )}
        <p className="text-center">
          <Link to={"/"}>{t("backToHome")}</Link>
        </p>
      </Form>
    </div>
  );
};
