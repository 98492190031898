import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@store/reducers/root.reducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import io, { Socket } from "socket.io-client";

const NAME = "socket";

type ISocketState = {
  //   notifications: INotification[];
  socket: Socket | null;
  isConnected: boolean;
};
const initialState: ISocketState = {
  socket: null,
  isConnected: false,
};
export const SocketSlice = createSlice({
  name: NAME,
  initialState: initialState,
  reducers: {
    connectSocket: (
      state,
      {
        payload: { endpoint, token },
      }: PayloadAction<{ token: string; endpoint: string }>
    ) => {
      try {
        const socket = io(`${endpoint}`, {
          reconnectionDelay: 10000,
          reconnectionDelayMax: 100000,
          // TODO: change to true
          autoConnect: false,
          auth: {
            token,
          },
        });
        return { ...state, socket };
      } catch (err) {
        return { ...state, socket: null };
      }
    },
    setSocketState: (
      state,
      { payload: isConnected }: PayloadAction<ISocketState["isConnected"]>
    ) => {
      console.log("socket status : ", isConnected);

      return { ...state, isConnected };
    },
  },
});

// Action creators are generated for each case reducer function
export const SocketActions = SocketSlice.actions;
export const socketSelector = (state: RootState) => state.socket;

export const SocketReducer = persistReducer(
  {
    storage: storage,
    key: NAME,
    debug: false,
    whitelist: [],
  },
  SocketSlice.reducer
);
