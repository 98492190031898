import { useSocket } from "@/hooks/useSocket";
import { DATA_SELECTOR, GET_COLS } from "@/queries";
import { FormActions, formSelector } from "@/store/reducers/form.reducer";
import { socketSelector } from "@/store/reducers/socket.reducer";
import { getValFromArray } from "@/utils";
import { useLazyQuery } from "@apollo/client";
import { Action } from "@components/dynamic/Action";
import { Icon } from "@components/dynamic/Icon";
import { CustomTableProps, Table } from "@components/dynamic/Table";
import { Filters } from "@components/Filters";
import Button from "@components/ui/buttons/Button";
import { useAction } from "@hooks/useAction";
import { IAction } from "@interfaces/action.interface";
import { DragSortTable, ICol, IColType } from "@interfaces/col.interface";
import { IFilter } from "@interfaces/filter.interface";
import { IScope } from "@interfaces/scope.interface";
import { Drawer, Input, PageHeader, Select, Space, Tooltip } from "antd";
import { ColumnType } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";
import classNames from "classnames";
import { log } from "console";
import * as _ from "lodash";
import { isEqual } from "lodash";
import * as Phosphor from "phosphor-react";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { modalSelector, ModalActions } from "../store/reducers/modal.reducer";

type Props = {
  name: string;
  userID: number;
  showSortColumns?: boolean;
  showSearch?: boolean;
  showTitle?: boolean;
  paramsItem?: any;
  params?: {
    name: string;
    source: string | any;
  }[];
  dataKeyName?: string;
  filters?: any[];
  scope?: IScope[];
  visible?: boolean;
  perPageData: number[];
};

type StateT = {
  searchWord: string;
  deleteId: string;
  deleteLoading: boolean;
  activeId: string;
  activeLoading: boolean;
  colsVisible: boolean;
  sortCols: DragSortTable[];
  page: number;
  pageSize: number;
  total: number;
  action?: IAction;
  // general_actions?: IAction[];
  item?: any;

  data_query: string;
  query: string;
  sorttype: string;
  sorter: string;

  params: any[];
  filters: IFilter[];

  data: any[];
  loading: boolean;

  // TODO
  table_name: string;
  pagination: any;
  actions: any;
  general_actions: IAction[];
  bulk_actions: IAction[];
  cols_columns: any;
  cols_data: any;
  colFilters: IFilter[];
  inited: boolean;
  selectedRowKeys: string[];

  totalSelected: number;
  allTotal: number;

  showSearch?: boolean;
  showSortColumns?: boolean;
  // selected: 5
  // total: 81
};

type GetAllDataT = {
  query: string;
  page: number;
  perPage: number;
  sorter: string;
  sorttype: string;
  filters: IFilter[];
  params: any[];
  general_search: string;
  cols: ICol;
};

const DynamicTable: React.FC<Props> = ({
  name,
  userID,
  showTitle = true,
  params = [],
  paramsItem,
  dataKeyName = "dataProvider",
  scope = [],
  perPageData,
  visible,
}) => {
  const [searchParams] = useSearchParams();
  const timeout = React.useRef<NodeJS.Timeout>();
  const [selectQuery, { loading: selectLoading, error }] = useLazyQuery(
    DATA_SELECTOR,
    {
      variables: {
        config: {
          page: 1,
          perPage: perPageData[0],
          sorttype: "asc",
          sorter: "id",
          filters: [] as IFilter[],
          general_search: "",
          params: [] as any[],
          action: "",
          id: 0,
        },
        name,
      },
      fetchPolicy: "no-cache",
    }
  );

  const { isConnected, socket } = useSelector(socketSelector);

  const rooms = scope.find((e: any) => e.name === "rooms")?.array || [];
  const ws_events = scope.find((e: any) => e.name === "ws_events")?.array || [];

  const { listenOnRoom, joinRoom, leaveRoom, removeListner } = useSocket();

  const { reload_datatable } = useSelector(modalSelector);
  const { lastInput } = useSelector(formSelector);
  const lastInputRef = React.useRef<any>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    lastInputRef.current = lastInput;
  }, [lastInput]);

  const { t, i18n } = useTranslation();

  const { doQuery } = useAction();
  const [state, setState] = React.useState<StateT>({
    page: 1,
    pageSize: perPageData[0],
    total: 0,

    searchWord: "",
    deleteId: "",
    deleteLoading: false,
    activeId: "",
    activeLoading: false,
    colsVisible: false,
    sortCols: [],
    action: undefined,
    item: undefined,

    data_query: "",
    query: "",
    sorttype: "asc",
    sorter: "id",

    params: [],
    filters: [],
    data: [],
    loading: true,

    table_name: "",
    pagination: undefined,
    actions: [],
    general_actions: [],
    bulk_actions: [],
    cols_columns: [],
    cols_data: [],
    colFilters: [],
    inited: false,
    selectedRowKeys: [],

    totalSelected: 0,
    allTotal: 0,

    showSearch: false,
    showSortColumns: false,

    // selected: 5
    // total: 81
  });
  const ref_page = React.useRef(1);
  const ref_pageSize = React.useRef(perPageData[0]);
  const ref_sorttype = React.useRef("asc");
  const ref_sorter = React.useRef("id");
  const ref_filters = React.useRef<IFilter[]>([]);
  const ref_data_query = React.useRef("");

  const [columns, setColumns] = useState<ICol>([]);
  const [openFilters, setOpenFilters] = React.useState(false);

  React.useEffect(() => {
    if (isConnected && state.data_query) {
      handleSocketData();
    }
    return () => {
      if (isConnected && state.data_query) {
        if (rooms.length) {
          rooms.forEach((room) => {
            leaveRoom(room.value);
          });
        }
        if (ws_events) {
          ws_events.forEach((event) => {
            removeListner(event.name);
          });
        }
      }
    };
  }, [rooms, ws_events, isConnected, state.data_query]);

  React.useEffect(() => {
    getAllColsByClient({});
  }, []);

  const getQuery = () => {
    const query: any[] = [];

    for (const entry of searchParams.keys()) {
      query.push({
        name: entry,
        value: searchParams.get(entry),
      });
    }

    return query;
  };

  const doSelect = ({
    action,
    id = "-1",
  }: {
    action:
      | "select_row"
      | "select_page"
      | "select_all"
      | "unselect_row"
      | "unselect_page"
      | "unselect_all";
    id?: string;
  }) => {
    return selectQuery({
      variables: {
        config: {
          page: state.page,
          perPage: state.pageSize,
          sorttype: state.sorttype,
          sorter: state.sorter,
          filters: state.filters,
          general_search: state.searchWord,
          params: [...getQuery(), ...params],
          action: action,
          id: parseInt(id) || -1,
        },
        name: name,
      },
    }).then((res) => {
      setState((s) => ({ ...s, totalSelected: res.data?.dataSelector }));

      return res;
    });
  };

  const rowSelection: TableRowSelection<any> = {
    // selectedRowKeys,
    // onChange: onSelectChange,
    selections: [
      {
        key: "select_all",
        text: "select_all",
        onSelect: (selectedRowKeys) => {
          setState((s) => ({
            ...s,
            selectedRowKeys: selectedRowKeys as string[],
          }));

          doSelect({
            action: "select_all",
          });
        },
      },
      {
        key: "unselect_all",
        text: "unselect_all",
        onSelect: (selectedRowKeys) => {
          setState((s) => ({
            ...s,
            selectedRowKeys: [],
          }));

          doSelect({
            action: "unselect_all",
          });
        },
      },
      // AntdTable.SELECTION_ALL,
      // AntdTable.SELECTION_INVERT,
      // AntdTable.SELECTION_NONE,
      // {
      //   key: "odd",
      //   text: "Select Odd Row",
      //   onSelect: (changableRowKeys) => {
      //     let newSelectedRowKeys = [];
      //     newSelectedRowKeys = changableRowKeys.filter((_, index) => {
      //       if (index % 2 !== 0) {
      //         return false;
      //       }
      //       return true;
      //     });
      //     // setSelectedRowKeys(newSelectedRowKeys);
      //   },
      // },
      // {
      //   key: "even",
      //   text: "Select Even Row",
      //   onSelect: (changableRowKeys) => {
      //     let newSelectedRowKeys = [];
      //     newSelectedRowKeys = changableRowKeys.filter((_, index) => {
      //       if (index % 2 !== 0) {
      //         return true;
      //       }
      //       return false;
      //     });
      //     // setSelectedRowKeys(newSelectedRowKeys);
      //   },
      // },
    ],
  };

  const getAllColsByClient = ({
    cols = columns,
    fetchData = true,
  }: {
    cols?: ICol;
    fetchData?: boolean;
  }) => {
    setState((s) => ({ ...s, loading: true }));

    let q: any[] = [];

    for (const entry of searchParams.keys()) {
      q.push({
        name: entry,
        value: searchParams.get(entry),
      });
    }

    const colsVairables = {
      name: name,
      userID: userID,
      dtinput: { columns: cols },
      params: [...q, ...params],
    };

    doQuery({
      scope,
      query: GET_COLS,
      fetchPolicy: "no-cache",
      item: undefined,
      variables: colsVairables,
    }).then((cols: any) => {
      const data_query: string = cols?.data?.dtConfig?.data_query
        ? cols?.data?.dtConfig?.data_query
        : "";

      const pagination: string = cols?.data?.dtConfig?.pagination
        ? cols?.data?.dtConfig?.pagination
        : false;
      const showSearch: boolean = cols?.data?.dtConfig?.general_search
        ? cols?.data?.dtConfig?.general_search
        : false;
      const showSortColumns: boolean = cols?.data?.dtConfig?.sort_columns
        ? cols?.data?.dtConfig?.sort_columns
        : false;
      const actions: IAction[] = cols?.data?.dtConfig?.actions
        ? cols?.data?.dtConfig?.actions
        : [];
      const general_actions: IAction[] = cols?.data?.dtConfig?.general_actions
        ? cols?.data?.dtConfig?.general_actions
        : [];
      const bulk_actions: IAction[] = cols?.data?.dtConfig?.bulk_actions
        ? cols?.data?.dtConfig?.bulk_actions
        : [];
      const cols_columns: ColumnType<any>[] = cols?.data?.dtConfig?.columns
        ? cols?.data?.dtConfig?.columns
        : [];
      const cols_data: any = cols?.data?.dtConfig?.data
        ? cols?.data?.dtConfig?.data
        : undefined;

      const table_name: string = cols?.data?.dtConfig?.title
        ? cols?.data?.dtConfig?.title
        : "";
      const colFilters: any[] = cols?.data?.dtConfig?.filters
        ? cols?.data?.dtConfig?.filters
        : [];

      const sortCols: DragSortTable[] = [];

      const sortedCols = _.orderBy(cols_columns, ["weight"], ["asc"]);

      const _cols: ICol = sortedCols?.map((col: any) => {
        const c: IColType = {
          title: col.label,
          dataIndex: col.dataIndex,
          key: col.key,
          visible: col.visible,
          sorter: col.sorter,

          // filteredropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          //   <div style={{ padding: 8 }}>
          //     <Input
          //       // ref={searchInput}
          //       // placeholder={`Search ${dataIndex}`}
          //       value={selectedKeys[0]}
          //       // onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          //       // onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          //       style={{ marginBottom: 8, display: 'block' }}
          //     />
          //     <Space>
          //       <Button
          //         type="primary"
          //         // onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          //         icon={<Phosphor.Pen />}
          //         size="small"
          //         style={{ width: 90 }}
          //       >
          //         Search
          //       </Button>
          //       <Button
          //         // onClick={() => clearFilters && handleReset(clearFilters)}
          //         size="small"
          //         style={{ width: 90 }}
          //       >
          //         Reset
          //       </Button>
          //       <Button
          //         type="link"
          //         size="small"
          //         onClick={() => {
          //           // confirm({ closeDropdown: false });
          //           // setSearchText((selectedKeys as string[])[0]);
          //           // setSearchedColumn(dataIndex);
          //         }}
          //       >
          //         Filter
          //       </Button>
          //     </Space>
          //   </div>
          // ),
          // filterIcon: (filtered: boolean) => (
          //   <Phosphor.Gear style={{ color: filtered ? '#1890ff' : undefined }} />
          // ),

          render: (text: string, record: any) => {
            const meta = (record?.meta || []) as IScope[];
            const transforms =
              meta.find((e) => e.name === "transforms")?.array || [];

            const transform = transforms.find((e) => e.name === col.dataIndex);
            if (transforms.length > 0 && transform) {
              return (
                <a
                  dangerouslySetInnerHTML={{
                    __html: transform.value,
                  }}
                ></a>
              );
            }

            return <a>{text}</a>;
          },
        };
        sortCols.push({
          title: col.label,
          key: col.key,
          visible: col.visible,
        });
        return c;
      });

      setColumns(_cols);

      if (data_query && fetchData) {
        getAllDataByClient({
          query: data_query,
          page: 1,
          perPage: state.pageSize,
          sorter: state.sorter,
          sorttype: state.sorttype,
          filters: state.filters,
          params: [...getQuery(), ...params],
          general_search: state.searchWord,
          cols: _cols,
        });
      }

      ref_data_query.current = data_query;

      setState((s) => ({
        ...s,
        sortCols,
        table_name,
        pagination,
        actions,
        general_actions,
        bulk_actions,
        showSearch,
        showSortColumns,
        cols_columns,
        cols_data,
        data_query,
        colFilters,
        loading: false,
        inited: true,
      }));
    });
  };

  const getAllDataByClient = ({
    query = state.query,
    // query = data_query,
    page = 1,
    perPage = perPageData[0],
    sorter,
    sorttype,
    filters = [],
    params = [],
    general_search = "",
    cols,
  }: GetAllDataT) => {
    setState((s) => ({ ...s, loading: true }));

    let newQuery = query + "";

    newQuery = newQuery.replaceAll(
      `@@cols@@`,
      ` id ${cols
        .filter((e) => e.visible)
        .map((e) => e.dataIndex)
        .join(" ")} `
    );

    let filterString = "[]";

    state.filters.forEach((filter: any, index) => {
      if (index === 0) filterString = " [ ";

      Object.keys(filter).forEach((key, index2) => {
        const value = filter[key] as any;
        if (index2 === 0) filterString += " { ";

        filterString += ` ${key} : ${
          Array.isArray(value) ? JSON.stringify(value) : `"${value}"`
        }, `;

        if (index2 === Object.keys(filter).length - 1) filterString += " }, ";
      });

      // filterString += `${index}=${state.filters[index].join(",")}&`;
      if (index === state.filters.length - 1) filterString += " ] ";
    });

    newQuery = newQuery.replaceAll(
      `@@filters@@`,
      `${JSON.stringify(filterString)}`
    );

    setState((s) => ({ ...s, query: newQuery }));

    doQuery({
      scope,
      query: query,
      fetchPolicy: "no-cache",
      item: undefined,
      variables: {
        config: {
          page,
          perPage,
          sorter,
          sorttype,
          filters,
          params,
          general_search,
        },
      },
    }).then((res: any) => {
      const data = res?.data?.dataProvider?.data || [];
      const page = res?.data?.[dataKeyName]?.pagination?.currentPage || 1;
      const pageSize = res?.data?.[dataKeyName]?.pagination?.perPage || 10;
      const total = res?.data?.[dataKeyName]?.pagination?.total || 0;
      const totalSelected = res?.data?.[dataKeyName]?.selected || 0;
      const allTotal = res?.data?.[dataKeyName]?.total || 0;
      if (state.filters.length || query) {
        if (lastInputRef.current && lastInputRef.current?.focus) {
          setTimeout(() => {
            lastInputRef.current?.focus();
          }, 200);
        }
      }
      // setState((s) => ({ ...s, data: [] }));
      ref_page.current = page;
      ref_pageSize.current = pageSize;
      setState((s) => ({
        ...s,
        data,
        page,
        pageSize,
        total,
        loading: false,
        totalSelected,
        allTotal,
      }));
    });
  };

  const handleSocketData = React.useCallback(() => {
    if (rooms.length) {
      if (rooms.length) {
        rooms.forEach((room) => {
          joinRoom(room.value);
        });
      }
    }
    if (ws_events) {
      ws_events.forEach((event) => {
        listenOnRoom(event.name, (data: any) => {
          const type = getValFromArray(event.array as [], "type");
          if (type === "refresh_row" || type === "remove_row") {
            getAllDataByClient({
              query: state.data_query,
              page: ref_page.current,
              perPage: ref_pageSize.current,
              sorter: ref_sorter.current,
              sorttype: ref_sorttype.current,
              filters: ref_filters.current,
              params: [...getQuery(), ...params],
              general_search: state.searchWord,
              cols: columns,
            });
          }
        });
      });
    }
  }, [rooms, ws_events, socket, state.data_query, columns, params]);

  React.useEffect(() => {
    if (reload_datatable) {
      dispatch(ModalActions.toggleModal({ reload_datatable: false }));
      setTimeout(() => {
        getAllDataByClient({
          query: state.data_query,
          page: state.page,
          perPage: state.pageSize,
          sorter: state.sorter,
          sorttype: state.sorttype,
          filters: state.filters,
          params: [...getQuery(), ...params],
          general_search: state.searchWord,
          cols: columns,
        });
      }, 100);
    }
  }, [reload_datatable]);

  // React.useEffect(() => {
  //   if (state.item && state.action) {

  //     doAction({ item: state.item, action: state.action }).then((data) => {
  //       getAllDataByClient({
  //         query: state.query,
  //         page: state.page,
  //         perPage: state.pageSize,
  //         sorter: state.sorter,
  //         sorttype: state.sorttype,
  //         filters: state.filters,
  //         params: [...query, ...params],
  //         general_search: state.searchWord,
  //         cols: columns,
  //       });

  //       // refetch({
  //       //   config: {
  //       //     page: state.page,
  //       //     perPage: state.pageSize,
  //       //     sorter: state.sorter,
  //       //     sorttype: state.sorttype,
  //       //     filters: state.filters,
  //       //     params: params,
  //       //     general_search: state.searchWord,

  //       //     // params: [generalParam!]
  //       //   },
  //       // });
  //     });
  //   }
  // }, [state.action, state.item]);

  React.useEffect(() => {
    if (state.inited) {
      timeout.current = setTimeout(() => {
        getAllDataByClient({
          query: state.data_query,
          page: state.page,
          perPage: state.pageSize,
          sorter: state.sorter,
          sorttype: state.sorttype,
          filters: state.filters,
          params: [...getQuery(), ...params],
          general_search: state.searchWord,
          cols: columns,
        });
      }, 750);
    }

    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
  }, [state.searchWord]);

  React.useEffect(() => {
    setState((s) => ({
      ...s,
      selectedRowKeys: state.data
        .filter(
          (e) =>
            e?.meta?.find((ee: any) => ee?.name === "selected")?.value === "1"
        )
        .map((e) => e.id),
    }));
  }, [state.data]);

  // const onSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {

  const onSearch = (e: any) => {
    if (!state.inited) return;

    const searchWord: string = e.target.value || "";
    setState((s) => ({ ...s, searchWord, page: 1 }));
  };

  const changePagination = (page: number, pageSize: number, total: number) => {
    if (state.inited) {
      getAllDataByClient({
        query: state.query,
        page: page,
        perPage: pageSize,
        sorter: state.sorter,
        sorttype: state.sorttype,
        filters: state.filters,
        params: [...getQuery(), ...params],
        general_search: state.searchWord,
        cols: columns,
      });
      setState((s) => ({ ...s, page, pageSize }));
    }
  };

  // error && consoleLog("table error", { error });
  // colsError && consoleLog({ colsError });

  const onSaveColumns = () => {
    const _cols: any[] = [];

    state.sortCols.forEach((col) => {
      const _col: any = columns.find((e) => e.key === col.key);

      if (_col) {
        _col.visible = col.visible;
        _cols.push(_col);
      }
    });

    setColumns(_cols);
    setState((s) => ({ ...s, colsVisible: false }));
    getAllColsByClient({
      cols: _cols.map((col, index) => ({
        key: col.key,
        visible: col.visible,
        weight: index,
        sorter: col.sorter,
      })),
      fetchData: true,
    });
  };

  const handleActionOfColumns: CustomTableProps["getAction"] = (
    action,
    result
  ) => {
    if (action === "select") {
      const _colls = result as DragSortTable[];

      const newColls = state.sortCols.map((col) => {
        col.visible = _colls.map((e) => e.key).includes(col.key);

        return col;
      });

      setState((s) => ({ ...s, sortCols: newColls }));
    }
    if (action === "dragDrop") {
      const newColls = result as DragSortTable[];

      setState((s) => ({ ...s, sortCols: newColls }));
    }
  };

  const onChangeFilters = (filters: IFilter[]) => {
    if (isEqual(filters, ref_filters.current)) return;
    if (state.inited) {
      ref_filters.current = filters;
      setState((s) => ({ ...s, filters }));
      getAllDataByClient({
        query: state.query,
        page: 1,
        perPage: state.pageSize,
        sorter: state.sorter,
        sorttype: state.sorttype,
        filters: filters,
        params: [...getQuery(), ...params],
        general_search: state.searchWord,
        cols: columns,
      });
    }
  };

  const onGetAction: CustomTableProps["getAction"] = (action, result) => {
    if (action === "sort") {
      let sorttype = "asc";
      const { field, order } = result as {
        field: string;
        order?: string;
      };
      if (order === "descend") sorttype = "desc";
      if (order === "ascend") sorttype = "asc";
      if (!order) sorttype = "asc";

      // const sorter = sorttype ? `${field}` : "id";
      const sorter = field;

      if (!sorter) return;

      ref_sorter.current = sorter;
      ref_sorttype.current = sorttype;

      setState((s) => ({
        ...s,
        sorter,
        sorttype,
      }));

      getAllDataByClient({
        query: state.query,
        page: sorter ? 1 : state.page,
        perPage: state.pageSize,
        sorter: sorter,
        sorttype: sorttype,
        filters: state.filters,
        params: [...getQuery(), ...params],
        general_search: state.searchWord,
        cols: columns,
      });
    }
  };

  const searchInputRef = React.useRef(null);

  return (
    <div className={classNames(visible === false && "d-none")}>
      <Drawer
        title={t("sort_columns")}
        visible={state.colsVisible}
        onClose={() => {
          setState((s) => ({ ...s, colsVisible: false }));
        }}
        placement={i18n.language === "ar" ? "left" : "right"}
        width={500}
        // onCancel={() => {
        //   setState((s) => ({ ...s, colsVisible: false }));
        // }}
        bodyStyle={{ padding: 0 }}
        footer={
          <div className="">
            <Space align="start">
              <Button
                type="primary"
                danger
                onClick={() => {
                  setState((s) => ({ ...s, colsVisible: false }));
                }}
              >
                {t("cancel")}
              </Button>
              <Button type="primary" onClick={onSaveColumns}>
                {t("save")}
              </Button>
            </Space>
          </div>
        }
      >
        <div
          className=""
          // style={{ maxHeight: "500px", overflowY: "scroll" }}
        >
          <Table
            // rowKey={"key"}
            rowKey={"key"}
            pagination={false}
            data={state.sortCols}
            selectedRowKeys={columns
              .filter((e) => e.visible)
              .map((e) => e.key + "")}
            columns={[
              {
                title: t("sort_columns"),
                dataIndex: "title",
                key: "key",
                width: 0,
                render: (title: string) => {
                  return <a>{title}</a>;
                },
              },
            ]}
            page={state.page}
            pageSize={state.pageSize}
            total={state.total}
            dragDrop
            getAction={handleActionOfColumns}
          />
        </div>
      </Drawer>

      <div className="table">
        {/* // TODO */}
        <PageHeader
          title={
            <div className="table-filters-main p-0">
              {state.showSearch ? (
                <Input
                  placeholder={t("search")}
                  ref={searchInputRef}
                  // allowClear
                  disabled={!state.inited}
                  onKeyUp={onSearch}
                  prefix={<Phosphor.MagnifyingGlass />}
                  suffix={undefined}
                  onFocus={() => {
                    dispatch(
                      FormActions.saveInput({
                        lastInput: searchInputRef.current,
                      })
                    );
                  }}
                />
              ) : (
                <div style={{ width: "100%" }}></div>
              )}
              {!!state.pagination && (
                <Select
                  bordered={false}
                  value={state.pageSize}
                  onChange={(pageSize) => {
                    changePagination(1, parseInt(pageSize + ""), state.total);
                  }}
                >
                  {perPageData.map((one) => (
                    <Select.Option value={one} key={`perpage-${one}`}>
                      {t("per_page", { number: one })}
                    </Select.Option>
                  ))}
                </Select>
              )}
              {/* <Badge count={3}> */}
              {state.colFilters.length > 0 && (
                <Button
                  size="small"
                  variant="light"
                  types="bordered"
                  onClick={() => setOpenFilters(!openFilters)}
                >
                  <Phosphor.Sliders />
                  {t("filters")}
                </Button>
              )}
              {/* </Badge> */}
            </div>
          }
          extra={
            state.general_actions && [
              ...state.general_actions?.map(
                (action: IAction, index: number) => {
                  return (
                    <Action
                      key={`general-actions-table-${index}`}
                      item={undefined}
                      filters={state.filters}
                      action={action}
                      // type="general"
                    />
                  );
                }
              ),
              state.showSortColumns && (
                <a key={`sort-button-key`}>
                  <Tooltip title={t("sort_columns")}>
                    <Button
                      onClick={() => {
                        setState((s) => ({
                          ...s,
                          colsVisible: !s.colsVisible,
                        }));
                      }}
                      icon={<Icon name={"SortAscending"} />}
                    ></Button>
                  </Tooltip>
                </a>
              ),
              ...state.bulk_actions?.map((action: IAction, index: number) => {
                return (
                  <Action
                    customBadge={state.totalSelected}
                    key={`general-actions-table-bulk_actions-${index}`}
                    item={undefined}
                    action={action}
                    filters={state.filters}
                    disabled={state.totalSelected === 0}
                    extraParams={[
                      // ...getQuery(),
                      ...state.params,
                    ]}
                  />
                );
              }),
            ]
          }
        />

        {/* <PageHeader
          // subTitle={t("bulk_actions")}
          extra={state.bulk_actions?.map((action: IAction, index: number) => {
            return (
              <Action
                customBadge={state.totalSelected}
                key={`general-actions-table-${index}`}
                item={undefined}
                action={action}
                disabled={state.totalSelected === 0}
                extraParams={[
                  // ...getQuery(),
                  ...params,
                ]}
              />
            );
          })}
        /> */}

        {/* bulk_actions */}
        {state.colFilters.length > 0 && (
          <div className="table-filters">
            <div
              className={`table-filters-collapse ${
                openFilters ? "opened" : ""
              }`}
            >
              <div
                className="table-filters-collapse-container"
                hidden={!openFilters}
              >
                <Filters
                  filters={state.colFilters}
                  scope={[]}
                  loading={!state.inited}
                  onChangeFilters={onChangeFilters}
                />
              </div>
            </div>
          </div>
        )}
        <Table
          {...(state.bulk_actions.length > 0
            ? {
                rowSelection: {
                  ...rowSelection,
                  onChange(selectedRowKeys, selectedRows, { type }) {
                    switch (type) {
                      case "all": {
                        setState((s) => ({
                          ...s,
                          selectedRowKeys: selectedRowKeys as string[],
                        }));
                        //           const isExist = state.selectedRowKeys.find(
                        //   (e) => e === record?.id
                        // );

                        doSelect({
                          action:
                            selectedRowKeys.length > 0
                              ? "select_page"
                              : "unselect_page",
                        });

                        break;
                      }

                      case "single": {
                        break;
                      }

                      default:
                        break;
                    }
                  },
                  onSelect: (record: any, selected: boolean) => {
                    let selectedRowKeys: string[] = [];

                    const isExist = state.selectedRowKeys.find(
                      (e) => e === record?.id
                    );
                    if (isExist) {
                      selectedRowKeys = state.selectedRowKeys.filter(
                        (e) => e !== record?.id
                      );
                    } else {
                      selectedRowKeys = [...state.selectedRowKeys, record?.id];
                    }

                    setState((s) => ({
                      ...s,
                      selectedRowKeys,
                    }));

                    doSelect({
                      action: selected ? "select_row" : "unselect_row",
                      id: record?.id,
                    }).then(() => {});
                  },
                  selectedRowKeys: state.selectedRowKeys,
                },
              }
            : {
                hideSelection: true,
              })}
          data={
            state.data?.map((item: any) => ({
              id: item.id,
              ...item.object,
              ...item,
            }))

            // data?.[dataKeyName]?.data ? data?.[dataKeyName]?.data?.map((item: any) => ({ id: item.id, ...item.object,  ...item, })) : []
          }
          rowKey={"id"}
          getAction={onGetAction}
          loading={state.loading}
          columns={
            state.actions.length > 0
              ? [
                  ...columns.filter((e) => e.visible),
                  {
                    title: t("actions"),
                    key: "actions",
                    width: 125,
                    render: (item: any) => {
                      return (
                        <Space size="middle">
                          {/* // TODO */}
                          {state.actions?.map(
                            (action: IAction, index: number) => {
                              return (
                                <Action
                                  item={item}
                                  action={action}
                                  filters={state.filters}
                                  key={`actions-${index}`}
                                  scope={scope}
                                  extraParams={item.meta}
                                  actionClicked={() => {
                                    getAllDataByClient({
                                      query: state.query,
                                      page: state.page,
                                      perPage: state.pageSize,
                                      sorter: state.sorter,
                                      sorttype: state.sorttype,
                                      filters: state.filters,
                                      params: [...getQuery(), ...params],
                                      general_search: state.searchWord,
                                      cols: columns,
                                    });
                                  }}
                                />
                              );
                            }
                          )}
                        </Space>
                      );
                    },
                  },
                ]
              : [...columns.filter((e) => e.visible)]
          }
          // selectedRowKeys={state.data
          //   .filter(
          //     (e) =>
          //       e?.meta?.find((ee: any) => ee?.name === "selected")?.value ===
          //       "1"
          //   )
          //   .map((ee) => ee.id)}
          pagination={!!state.pagination}
          page={
            state.page
            // data?.[dataKeyName]?.pagination?.currentPage
          }
          allTotal={state.allTotal}
          totalSelected={state.totalSelected}
          pageSize={state.pageSize}
          total={state.total}
          changePagination={changePagination}
        />
      </div>
    </div>
  );
};

export default DynamicTable;
