import { useRedirect } from "@/hooks/useRedirect";
import { Card } from "@components/dynamic/Card";
import Button from "@components/ui/buttons/Button";
import { useAction } from "@hooks/useAction";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import {
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import { Rule } from "antd/lib/form";
import classNames from "classnames";
import { Check, NotePencil, X } from "phosphor-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const RegexParser = require("regex-parser");
const { Option } = Select;

interface IProps {
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  form: FormInstance<any>;
  type?: "static" | "lazyload";

  formState: any;
  setFormState: any;
  editInline?: boolean;

  onChange?: (value: any, variable: string) => void;
  visible?: boolean;
  disabled?: string;
}

interface IState {
  search: string;
  loading: boolean;
  result: { name: string; title: string }[];
  allResult: { name: string; title: string }[];

  isValid: boolean;
  editMode: boolean;
  value: string;
  editedValue: string;
}

export const FieldDropdown: React.FC<IProps> = ({
  block,
  scope = [],
  form,
  formState,
  setFormState,
  onChange,
  type = "static",
  editInline,
  visible,
  disabled,
}) => {
  const { checkRedirect } = useRedirect();
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const config: IScope[] = block?.config || [];

  const variable = config.find((e) => e.name === "variable")?.value || "";
  const placeholder = config.find((e) => e.name === "placeholder")?.value || "";
  const required = config.find((e) => e.name === "required")?.value === "1";
  const pattern = config.find((e) => e.name === "pattern")?.value;
  const validation_message = config.find(
    (e) => e.name === "validation_message"
  )?.value;

  const graphql = config.find((e) => e.name === "graphql")?.value || "";
  const expanded = config.find((e) => e.name === "expanded")?.value === "1";
  const expandable = config.find((e) => e.name === "expandable")?.value === "1";

  const allowClear = config.find((e) => e.name === "allowClear")?.value === "1";
  const mode = config.find((e) => e.name === "mode")?.value as
    | "multiple"
    | "tags"
    | undefined;

  const options = config.find((e) => e.name === "options")?.array || [];

  const query_accessor =
    config.find((e) => e.name === "query_accessor")?.value || "";
  const options_attribute =
    config.find((e) => e.name === "options_attribute")?.value || "";
  const selected_attribute =
    config.find((e) => e.name === "selected_attribute")?.value || "";
  const selected = config.find((e) => e.name === "selected")?.value || "";

  const showsearch = config.find((e) => e.name === "showsearch")?.value === "1";
  const searchtype = config.find((e) => e.name === "searchtype")?.value as
    | "graphql"
    | string;
  const searchkey = config.find((e) => e.name === "searchkey")?.value || "";
  const can_do = config.find((e) => e.name === "can_do")?.value === "1";
  const scope_variables =
    config.find((e) => e.name === "scope_variables")?.array || [];

  const vars: string[] = scope_variables.map((one) => one.name);
  const auto_save = config.find((e) => e.name === "auto_save")?.value || "";
  const query = config.find((e) => e.name === "query")?.value;

  const { doQuery, doMutateQraphQl, replaceFromScope } = useAction();

  const [state, setState] = useState<IState>({
    search: "",
    loading: false,
    result: [],
    allResult: [],

    isValid: false,
    editMode: false,
    value: block?.body || "",
    editedValue: block?.body || "",
  });

  const [currentValue, setCurrentValue] = useState<any>(null);

  const getData = (q?: string) => {
    const newVars: any = {};
    vars.forEach((one) => {
      if (formState[one]) {
        newVars[one] = formState[one];
      }
    });
    if (type === "lazyload") {
      setState((s) => ({ ...s, loading: true }));
      doQuery({
        query: graphql,
        scope: block?.config,
        item: { [searchkey]: q, ...newVars },
      })
        .then((data: any) => {
          if (data?.data) {
            if (data.data[query_accessor]) {
              if (
                data.data[query_accessor] &&
                data.data[query_accessor][selected_attribute]
              ) {
                form.setFieldsValue({
                  [variable]:
                    data.data[query_accessor][selected_attribute] + "",
                });
              }

              setState((s) => ({
                ...s,
                loading: false,
                result: data.data[query_accessor][options_attribute],
                allResult: data.data[query_accessor][options_attribute],
              }));
            }
            form.setFieldsValue({ [variable]: undefined });
          }
        })
        .finally(() => {
          setState((s) => ({
            ...s,
            loading: false,
          }));
        });
    }
  };

  useEffect(() => {
    if (graphql && !editInline) {
      if (vars.length === 0 && !formState[variable]) {
        getData();
      } else {
        let get = true;
        console.log({ vars, variable, formState, currentValue });

        vars.map((one) => {
          if (!formState[one] || currentValue == formState[one]) {
            get = false;
          } else {
            setCurrentValue(formState[one]);
          }
        });
        if (get && !formState[variable]) {
          getData();
        }
      }
    } else if (type === "lazyload" && searchtype === "local") {
      setState((s) => ({
        ...s,
        result: options.map((e: any) => ({
          name: e.value,
          title: e.name,
        })),
      }));
    } else if (type === "static") {
      setState((s) => ({
        ...s,
        result: options.map((e: any) => ({
          name: e.value,
          title: e.name,
        })),
      }));
      if (!setFormState[variable]) {
        setVal();
      }
    }
  }, [graphql, formState, currentValue, setFormState, onChange, selected]);

  const setVal = useCallback(() => {
    if (selected && !formState[variable]) {
      form.setFieldsValue({ [variable]: selected });
      setFormState((s: any) => ({ ...s, [variable]: selected }));
      onChange && onChange(selected, variable);
    }
  }, [formState, selected, onChange]);

  const { rules } = useMemo(() => {
    const rules: Rule[] = [];

    if (pattern) {
      rules.push({
        pattern: new RegExp(RegexParser(pattern)),
        message: validation_message,
      });
    }
    if (required) {
      rules.push({
        required: required,
        message: `${block?.title || block?.label} is required.`,
      });
    }

    return { rules };
  }, [required, pattern]);

  const onSearch = (e: any) => {
    setState((s) => ({ ...s, search: e.target.value }));
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(async () => {
      if (e.target.value) {
        if (graphql && type === "lazyload" && searchtype === "graphql") {
          getData(e.target.value);
        } else if (type === "lazyload" && searchtype === "local") {
          setState((s) => ({
            ...s,
            result: s.allResult.filter((res) =>
              res.title?.toLowerCase()?.includes(e.target.value.toLowerCase())
            ),
          }));
        } else if (type === "static") {
          setState((s) => ({
            ...s,
            result: options
              .filter(
                (e: any) =>
                  e.name
                    ?.toLowerCase()
                    ?.includes(e.target.value.toLowerCase()) ||
                  e.value?.toLowerCase()?.includes(e.target.value.toLowerCase())
              )
              .map((e: any) => ({
                name: e.value,
                title: e.name,
              })),
          }));
        }
      } else {
        if (graphql && type === "lazyload" && searchtype === "graphql") {
          getData();
        }
      }
    }, 500);
    setTimer(newTimer);
  };

  const renderOptions = () => {
    return state.result?.map((op, index: number) => {
      return (
        <Option key={`select-option-${op.name}-${index}`} value={op.name}>
          {op.title}
        </Option>
      );
    });
  };

  const onSelect = (e: any) => {
    setState((s) => ({ ...s, search: "" }));
  };

  const CardComponent = (
    <Card
      body={state.result.find((one) => one.name === state.value)?.title || ""}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      visible={visible}
      config={config}
      editBodyButton={
        can_do ? (
          <Button
            key="card-edit-action-edit-inline"
            // type="button"
            loading={state.loading}
            variant="primary"
            onClick={() => setState((s) => ({ ...s, editMode: true }))}
            icon={<NotePencil />}
          />
        ) : undefined
      }
    />
  );

  const save = () => {
    let query = "";

    if (required && !state.editedValue) return;

    if (pattern) {
      const expression = new RegExp(RegexParser(pattern));

      const isMatch = expression.test(state.editedValue);
      if (!isMatch) return;
    }

    query = replaceFromScope({
      scope: [{ name: variable, value: state.editedValue }],
      str: graphql + "",
      withQuate: false,
    });

    setState((s) => ({ ...s, loading: true }));

    doMutateQraphQl(query)
      .then(() => {
        checkRedirect(config);
        setState((s) => ({
          ...s,
          loading: false,
          editMode: false,
          value: s.editedValue,
        }));

        onChange && onChange(state.editedValue, variable);
      })
      .catch((e) => {
        setState((s) => ({ ...s, loading: false, editMode: false }));
      });
  };
  const cancel = () => {
    setState((s) => ({ ...s, editMode: false, editedValue: s.value }));
  };

  const Required = () => {
    return (
      !state.editedValue && (
        <div className="ant-form-item-explain ">
          <div role="alert" className="ant-form-item-explain-error">
            {variable} is required.
          </div>
        </div>
      )
    );
  };
  const Pattern = () => {
    if (pattern) {
      const expression = new RegExp(RegexParser(pattern));

      const isMatch = expression.test(state.editedValue);

      return (
        !isMatch && (
          <div className="ant-form-item-explain ">
            <div role="alert" className="ant-form-item-explain-error">
              {validation_message}
            </div>
          </div>
        )
      );
    } else return null;
  };

  const onFieldChange = (val: string) => {
    if (auto_save && auto_save === "on_change") {
      let q = "";

      if (pattern) {
        const expression = new RegExp(RegexParser(pattern));

        const isMatch = expression.test(val);
        if (!isMatch) return;
      }

      q = replaceFromScope({
        scope: [{ name: variable, value: val }],
        str: query + "",
        withQuate: false,
      });

      doMutateQraphQl(q)
        .then(() => {
          checkRedirect(config);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return editInline ? (
    state.editMode ? (
      <Card
        body={""}
        title={block?.title}
        expanded={expanded}
        classes={block?.classes}
        expandable={expandable}
        visible={visible}
        config={config}
      >
        <Row wrap={false} gutter={[16, 16]}>
          <Col flex="auto">
            <Select
              id={block?.name}
              filterOption={false}
              disabled={disabled === "1"}
              loading={state.loading}
              style={{ width: "100%" }}
              mode={mode}
              allowClear={allowClear}
              placeholder={placeholder}
              onSearch={onSearch}
              onSelect={onSelect}
              value={state.editedValue}
              onChange={(value) => {
                setState((s) => ({ ...s, editedValue: value }));
              }}
              showSearch={false}
              dropdownRender={(menu) => (
                <React.Fragment>
                  {showsearch && (
                    <React.Fragment>
                      <Space align="center" style={{ padding: "0 8px 4px" }}>
                        <Input
                          placeholder="Search ..."
                          onChange={onSearch}
                          value={state.search}
                        />
                      </Space>
                      <Divider style={{ margin: "8px 0" }} />
                    </React.Fragment>
                  )}
                  {menu}
                </React.Fragment>
              )}
            >
              {renderOptions()}
            </Select>
          </Col>
          <Col flex="none">
            <Space direction="horizontal" style={{}}>
              <Button
                variant="primary"
                type="primary"
                icon={<Check />}
                width={10}
                onClick={save}
                loading={state.loading}
              ></Button>
              <Button
                variant="danger"
                type="primary"
                icon={<X />}
                width={10}
                disabled={state.loading}
                onClick={cancel}
              ></Button>
            </Space>
          </Col>
        </Row>

        {Required()}
        {Pattern()}
      </Card>
    ) : (
      CardComponent
    )
  ) : (
    <Form.Item
      label={block?.title || block?.label}
      name={variable}
      rules={rules}
      className={classNames(visible === false && "d-none")}
    >
      <Select
        id={block?.name}
        filterOption={false}
        loading={state.loading}
        disabled={disabled === "1"}
        style={{ width: "100%" }}
        mode={mode}
        allowClear={allowClear}
        placeholder={placeholder}
        onSearch={onSearch}
        onSelect={onSelect}
        onChange={(value) => {
          form.setFieldsValue({ [variable]: value });
          setFormState((s: any) => ({ ...s, [variable]: value }));
          onFieldChange(value);
          onChange && onChange(value, variable);
        }}
        showSearch={false}
        dropdownRender={(menu) => (
          <React.Fragment>
            {showsearch && (
              <React.Fragment>
                <Space align="center" style={{ padding: "0 8px 4px" }}>
                  <Input
                    placeholder="Search ..."
                    onChange={onSearch}
                    value={state.search}
                  />
                </Space>
                <Divider style={{ margin: "8px 0" }} />
              </React.Fragment>
            )}
            {menu}
          </React.Fragment>
        )}
      >
        {renderOptions()}
      </Select>
    </Form.Item>
  );
};
