import { MAIN } from "@/config";
import { themeSelector } from "@/store/reducers/theme.reducer";
import { useSelector } from "react-redux";

export const useLogoSrc = () => {
  const theme = useSelector(themeSelector).theme;

  switch (MAIN) {
    case "sham":
      return theme === "dark"
        ? require("./../assets/img/logo-dark.png")
        : require("./../assets/img/logo.png");
    case "omar":
      return theme === "dark"
        ? require("./../assets/img/logo-omer-dark.png")
        : require("./../assets/img/logo-omer.png");
    case "mySchool":
      return theme === "dark"
        ? require("./../assets/img/mySchool-dark.png")
        : require("./../assets/img/mySchool-light.png");
    default:
  }
};
