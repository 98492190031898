import { useAction } from "@/hooks/useAction";
import { useRedirect } from "@/hooks/useRedirect";
import { configSelector } from "@/store/reducers/config.reducer";
import {
  calcGK,
  calcMGRS,
  calcUTM,
  convertDMSToDD,
  convertFromDMSToLatLng,
  convertFromDMToLatLng,
  convertLatLngToDM,
  convertLatLngToDMS,
  convertLatToDMObj,
  convertLatToDMSObj,
  detectSafari,
  getLatDegree,
  getLngDegree,
  gkToLatLon,
  mgrsToLatLon,
  utmToLatLon,
} from "@/utils";
import { Card } from "@components/dynamic/Card";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { Col, Input, Row, Select, Space } from "antd";
import GoogleMapReact from "google-map-react";
import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { useScreenshot } from "use-react-screenshot";
import Button from "../ui/buttons/Button";
import { Icon } from "./Icon";

interface Point {
  description: string;
  lat: string;
  lon: string;
  __typename: string;
}

type CoordinatesSystems =
  | "WGS84"
  | "WGS84(minutes)"
  | "WGS84(minutes, seconds)"
  | "GK"
  | "UTM"
  | "MGRS";

export const Map: React.FC<{
  block: IBlock;
  visible?: boolean;
  formState: any;
}> = ({ block, visible, formState }) => {
  const { google_maps_api_key } = useSelector(configSelector);
  const { t, i18n } = useTranslation();
  const markerRef = useRef(null);
  const [markers, setMarkers] = useState<any[]>([]);
  const [points, setPoints] = useState<Point[]>([]);
  const [state, setState] = useState<{
    lat: string;
    lng: string;
    x?: string;
    y?: string;
    mgrs?: string;
    type: CoordinatesSystems;
    loading: boolean;
    share_loading: boolean;
    dmsLat: string;
    dmsLng: string;
    dmLat: string;
    dmLng: string;
    dmsObj: {
      lat: { deg: string; min: string; sec: string };
      lng: { deg: string; min: string; sec: string };
    };
    dmObj: {
      lat: { deg: string; min: string };
      lng: { deg: string; min: string };
    };
  }>({
    lat: "0",
    lng: "0",
    x: "0",
    y: "0",
    mgrs: "",
    type: "WGS84",
    loading: false,
    share_loading: false,
    dmsLat: "",
    dmsLng: "",
    dmLat: "",
    dmLng: "",
    dmsObj: {
      lat: { deg: "0", min: "0", sec: "0" },
      lng: { deg: "0", min: "0", sec: "0" },
    },
    dmObj: { lat: { deg: "0", min: "0" }, lng: { deg: "0", min: "0" } },
  });

  const [mgrs, setMgrs] = useState("");
  const [utm, setUtm] = useState({
    x: 0,
    y: 0,
  });

  const [gk, setGk] = useState({
    x: 0,
    y: 0,
  });

  const [WGS84, setWGs84] = useState({
    lat: 0,
    lng: 0,
  });

  const [DMS, setDMS] = useState({
    lat: "0",
    lng: "0",
  });

  const [DM, setDM] = useState({
    lat: "0",
    lng: "0",
  });

  const [dmsObj, setDmsObj] = useState({
    lat: {
      deg: "0",
      min: "0",
      sec: "0",
    },
    lng: {
      deg: "0",
      min: "0",
      sec: "0",
    },
  });

  const [dmObj, setDmObj] = useState({
    lat: {
      deg: "0",
      min: "0",
    },
    lng: {
      deg: "0",
      min: "0",
    },
  });

  const config: IScope[] = block?.config || [];
  const expandable =
    config.find((e: any) => e.name === "expandable")?.value === "1";
  const expanded =
    config.find((e: any) => e.name === "expanded")?.value === "1";
  const defaultZoom: number = parseInt(
    config.find((e: any) => e.name === "defaultZoom")?.value || "5"
  );

  const height: number = parseInt(
    config.find((e: any) => e.name === "height")?.value || "500"
  );

  const penColor: string =
    config.find((e: any) => e.name === "penColor")?.value || "red";

  const live_update: boolean =
    config.find((e: any) => e.name === "live_update")?.value === "1" || false;

  const query: string =
    config.find((e: any) => e.name === "query")?.value || "";
  const share_query: string =
    config.find((e: any) => e.name === "share_query")?.value || "";

  const printable: boolean =
    config.find((e: any) => e.name === "printable")?.value === "1" || false;

  const sharable: boolean =
    config.find((e: any) => e.name === "sharable")?.value === "1" || false;
  const share_array: string[] =
    config
      .find((e: any) => e.name === "share_array")
      ?.array?.map((one) => one.value) || [];
  const graphql: string =
    config.find((e: any) => e.name === "graphql")?.value || "";

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const mapRef = useRef<GoogleMapReact>();
  const mapsRef = useRef<any>();

  const { doMutateQraphQl, replaceFromScope, doQuery } = useAction();

  const { checkRedirect } = useRedirect();

  const ref = createRef<any>();
  const [image, takeScreenshot] = useScreenshot();
  const getImage = () => takeScreenshot(ref.current);

  const screenshot = async () => {
    // const img = await takeScreenshot(ref.current);
    // triggerBase64Download(img, "image");

    var contentOfDiv = ref.current.innerHTML;

    const myWindow = window.open("", "", `width=1200,height=800`) as Window;
    myWindow?.document?.write(contentOfDiv);

    myWindow?.document?.close();
    myWindow?.focus();
    if (detectSafari()) {
      myWindow?.print();
      myWindow?.close();
    } else {
      myWindow.onload = () => {
        myWindow?.print();
        myWindow?.close();
      };
    }
  };

  useEffect(() => {
    const lat: string = config.find((e: any) => e.name === "lat")?.value || "0";
    const lng: string = config.find((e: any) => e.name === "lon")?.value || "0";
    const x: string = config.find((e: any) => e.name === "x")?.value || "0";
    const y: string = config.find((e: any) => e.name === "y")?.value || "0";
    const mgrs: string =
      config.find((e: any) => e.name === "mgrs")?.value || "0";
    const coordinate_system: CoordinatesSystems =
      (config.find((e: any) => e.name === "coordinate_system")
        ?.value as CoordinatesSystems) || "WGS84";
    if (coordinate_system === "WGS84") {
      calcAll({ lat: lat.toString(), lng: lng.toString() });
      setState((s) => ({ ...s, lat, lng, type: coordinate_system }));
    }
    if (coordinate_system === "WGS84(minutes)") {
      calcAll({ lat: lat.toString(), lng: lng.toString() });
      setState((s) => ({
        ...s,
        lat,
        lng,
        dmLngObj: convertLatToDMObj(Number(lat), Number(lng)),
        type: coordinate_system,
      }));
    }
    if (coordinate_system === "WGS84(minutes, seconds)") {
      calcAll({ lat: lat.toString(), lng: lng.toString() });
      setState((s) => ({
        ...s,
        lat,
        lng,
        dmsObj: convertLatToDMSObj(Number(lat), Number(lng)),
        type: coordinate_system,
      }));
    }
    if (coordinate_system === "MGRS") {
      const { lat, lon } = mgrsToLatLon({ mgrs });
      calcAll({ lat: lat.toString(), lng: lon.toString() });
      setState((s) => ({ ...s, lat, lng, type: coordinate_system }));
    }
    if (coordinate_system === "GK") {
      const { lat, lon } = gkToLatLon({ x, y });
      calcAll({ lat: lat.toString(), lng: lon.toString() });
      setState((s) => ({ ...s, lat, lng, type: coordinate_system }));
    }
    if (coordinate_system === "UTM") {
      const { lat, lon } = utmToLatLon({ x, y });
      calcAll({ lat: lat.toString(), lng: lon.toString() });
      setState((s) => ({ ...s, lat, lng, type: coordinate_system }));
    }
  }, [config]);

  const _onClick = ({
    x,
    y,
    lat,
    lng,
    event,
  }: GoogleMapReact.ClickEventValue) => {
    if (live_update) {
      setState((s) => ({ ...s, lat: lat.toString(), lng: lng.toString() }));
      calcAll({ lat: lat.toString(), lng: lng.toString() });
      const utm = calcUTM({ lat: lat.toString(), lon: lng.toString() });
      const mgrs = calcMGRS({ lat: lat.toString(), lon: lng.toString() });
      const gk = calcGK({ lat: lat.toString(), lon: lng.toString() });
      const dms = convertLatLngToDMS(lat, lng);
      const dm = convertLatLngToDM(lat, lng);
      const dmsObj = convertLatToDMSObj(lat, lng);
      const dmObj = convertLatToDMObj(lat, lng);
      if (state.type === "UTM") {
        setState((s) => ({ ...s, x: utm.x.toString(), y: utm.y.toString() }));
      }
      if (state.type === "GK") {
        setState((s) => ({ ...s, x: gk.x.toString(), y: gk.y.toString() }));
      }
      if (state.type === "MGRS") {
        setState((s) => ({ ...s, mgrs }));
      }
      if (state.type === "WGS84(minutes, seconds)") {
        setState((s) => ({
          ...s,
          dmsLat: dms.lat,
          dmsLng: dms.lng,
          dmsObj: dmsObj,
        }));
        setDmsObj(dmsObj);
        setDMS(dms);
      }
      if (state.type === "WGS84(minutes)") {
        setState((s) => ({ ...s, dmLat: dm.lat, dmLng: dm.lng, dmObj: dmObj }));
        setDmObj(dmObj);
        setDM(dm);
      }
    }
  };

  const getPoints = useCallback(
    async (center?: { lat: number; lng: number }) => {
      let _query = graphql;

      const a = Object.keys(formState).map((key) => {
        return {
          name: key,
          value: formState[key],
        };
      });

      _query = replaceFromScope({
        scope: [
          { name: "center_lat", value: center?.lat || state.lat.toString() },
          { name: "center_lon", value: center?.lng || state.lng.toString() },
          ...a,
        ],
        str: _query + "",
        withQuate: true,
      });

      doQuery({
        query: _query,
        withQuate: false,
      })
        .then((res: any) => {
          if (res.data.getCoordinates.coordinates) {
            const points: Point[] = res.data.getCoordinates.coordinates;
            setPoints(points);
            if (mapsRef.current) {
              const marksArray: any = [];
              markers.forEach((mark) => {
                mark.setMap(null);
              });
              points.forEach((pt) => {
                var pinColor = "#fcba63";

                var pinSVGFilled =
                  "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
                var labelOriginFilled = new mapsRef.current.Point(12, -5);

                var markerImage = {
                  // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
                  path: pinSVGFilled,
                  anchor: new mapsRef.current.Point(12, 17),
                  fillOpacity: 1,
                  fillColor: pinColor,
                  strokeWeight: 1,
                  strokeColor: "white",
                  scale: 1.5,
                  labelOrigin: labelOriginFilled,
                };

                const mark = new mapsRef.current.Marker({
                  position: {
                    lat: Number(pt.lat),
                    lng: Number(pt.lon),
                  },
                  map: mapRef.current,
                  label: pt.description,
                  icon: markerImage,
                });

                marksArray.push(mark);
              });

              setMarkers((s) => [...s, ...marksArray]);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [state.lat, state.lng]
  );

  useEffect(() => {
    if (graphql && state.lat !== "0" && state.lng !== "0") {
      getPoints();
    }
  }, [state.lat, state.lng]);

  const save = () => {
    let _query = query;

    const a = Object.keys(formState).map((key) => {
      return {
        name: key,
        value: formState[key],
      };
    });

    _query = replaceFromScope({
      scope: [
        { name: "lat", value: state.lat },
        { name: "lon", value: state.lng },
        { name: "x", value: state.x },
        { name: "y", value: state.y },
        { name: "mgrs", value: state.mgrs },
        { name: "coordinate_system", value: state.type },
        ...a,
      ],
      str: _query + "",
      withQuate: false,
    });

    setState((s) => ({ ...s, loading: true }));

    doMutateQraphQl(_query)
      .then(() => {
        checkRedirect(config);
      })
      .catch((e) => {
        setState((s) => ({ ...s, loading: false }));
      })
      .finally(() => {
        setState((s) => ({ ...s, loading: false }));
      });
  };

  const backEndShare = () => {
    let _query = share_query;

    const a = Object.keys(formState).map((key) => {
      return {
        name: key,
        value: formState[key],
      };
    });

    _query = replaceFromScope({
      scope: [
        { name: "lat", value: state.lat },
        { name: "lon", value: state.lng },
        { name: "x", value: state.x },
        { name: "y", value: state.y },
        { name: "mgrs", value: state.mgrs },
        { name: "coordinate_system", value: state.type },
        ...a,
      ],
      str: _query + "",
      withQuate: false,
    });

    setState((s) => ({ ...s, share_loading: true }));

    doMutateQraphQl(_query)
      .then(() => {
        checkRedirect(config);
      })
      .catch((e) => {
        setState((s) => ({ ...s, share_loading: false }));
      })
      .finally(() => {
        setState((s) => ({ ...s, share_loading: false }));
      });
  };

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(async () => {
      // @ts-ignore
      mapRef?.current?.setCenter(
        // @ts-ignore
        new window.google.maps.LatLng(
          Number(state.lat) || 0,
          Number(state.lng) || 0
        )
      );
    }, 500);
    setTimer(newTimer);
    calcAll({ lat: state.lat.toString(), lng: state.lng.toString() });
  }, [state.lat, state.lng]);

  const setMarker = (lat: string, lng: string) => {
    if (mapRef.current) {
      // @ts-ignore
      markerRef.current?.setMap(null);
      // @ts-ignore
      markerRef.current = new window.google.maps.Marker({
        position: {
          lat: Number(lat || state.lat) || 0,
          lng: Number(lng || state.lng) || 0,
        },
      });

      // @ts-ignore
      markerRef.current?.setMap(mapRef.current);
    }
  };

  const calcAll = ({
    lat,
    lng,
  }: {
    lat?: string | number;
    lng?: string | number;
  }) => {
    const utm = calcUTM({ lat: lat || state.lat, lon: lng || state.lng });
    const mgrs = calcMGRS({ lat: lat || state.lat, lon: lng || state.lng });
    const gk = calcGK({ lat: lat || state.lat, lon: lng || state.lng });
    const dms = convertLatLngToDMS(Number(lat), Number(lng));
    const dm = convertLatLngToDM(Number(lat), Number(lng));
    const dmsObj = convertLatToDMSObj(Number(lat), Number(lng));
    const dmObj = convertLatToDMObj(Number(lat), Number(lng));

    setDmObj(dmObj);
    setDmsObj(dmsObj);
    setMgrs(mgrs);
    setUtm(utm);
    setGk(gk);
    setDMS(dms);
    setDM(dm);
  };

  useEffect(() => {
    if (mapRef.current) {
      // @ts-ignore
      markerRef.current?.setMap(null);
      // @ts-ignore
      markerRef.current = new window.google.maps.Marker({
        position: {
          lat: Number(state.lat) || 0,
          lng: Number(state.lng) || 0,
        },
      });

      // @ts-ignore
      markerRef.current?.setMap(mapRef.current);
    }
  }, [state.lat, state.lng, state.type]);

  useEffect(() => {
    if (state.type === "UTM") {
      setState((s) => ({ ...s, x: utm?.x?.toString(), y: utm?.y?.toString() }));
    }
  }, [state.type]);

  useEffect(() => {
    if (state.type === "GK") {
      setState((s) => ({ ...s, x: gk?.x?.toString(), y: gk?.y?.toString() }));
    }
  }, [state.type]);

  useEffect(() => {
    if (state.type === "MGRS") {
      setState((s) => ({ ...s, mgrs }));
    }
  }, [state.type]);

  useEffect(() => {
    if (state.type === "WGS84(minutes)") {
      setState((s) => ({
        ...s,
        dmLat: DM.lat,
        dmLng: DM.lng,
        dmObj: dmObj,
      }));
    }
  }, [state.type]);

  useEffect(() => {
    if (state.type === "WGS84(minutes, seconds)") {
      setState((s) => ({
        ...s,
        dmsLat: DMS.lat,
        dmLng: DMS.lng,
        dmsObj: dmsObj,
      }));
    }
  }, [state.type]);

  useEffect(() => {
    if (state.type === "WGS84(minutes)") {
      // const { lat, lng } = convertFromDMToLatLng(
      //   `${state.dmObj.lat.deg}°${state.dmObj.lat.min}"${getLatDegree(
      //     Number(state.dmObj.lat.deg)
      //   )}`,
      //   `${state.dmObj.lng.deg}°${state.dmObj.lng.min}"${getLngDegree(
      //     Number(state.dmObj.lng.deg)
      //   )}`
      // );
      const lat = convertDMSToDD(
        state.dmObj.lat.deg,
        state.dmObj.lat.min,
        "0",
        getLatDegree(Number(state.dmObj.lat.deg))
      );
      const lng = convertDMSToDD(
        state.dmObj.lng.deg,
        state.dmObj.lng.min,
        "0",
        getLngDegree(Number(state.dmObj.lng.deg))
      );
      setState((s) => ({ ...s, lat: lat.toString(), lng: lng.toString() }));
    }
  }, [state.type, state.dmObj]);

  useEffect(() => {
    if (state.type === "WGS84(minutes, seconds)") {
      // const { lat, lng } = convertFromDMSToLatLng(
      //   `${state.dmsObj.lat.deg}°${state.dmsObj.lat.min}'${
      //     state.dmsObj.lat.sec
      //   }"${getLatDegree(Number(state.dmsObj.lat.deg))}`,
      //   `${state.dmsObj.lng.deg}°${state.dmsObj.lng.min}'${
      //     state.dmsObj.lng.sec
      //   }"${getLngDegree(Number(state.dmsObj.lng.deg))}`
      // );
      const lat = convertDMSToDD(
        state.dmsObj.lat.deg,
        state.dmsObj.lat.min,
        state.dmsObj.lat.sec,
        getLatDegree(Number(state.dmsObj.lat.deg))
      );
      const lng = convertDMSToDD(
        state.dmsObj.lng.deg,
        state.dmsObj.lng.min,
        state.dmsObj.lng.sec,
        getLngDegree(Number(state.dmsObj.lng.deg))
      );
      setState((s) => ({ ...s, lat: lat.toString(), lng: lng.toString() }));
    }
  }, [state.type, state.dmsObj]);

  return (
    <Card
      body={""}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      visible={visible}
      config={config}
    >
      {!google_maps_api_key ? (
        <div>please add map key</div>
      ) : (
        <div>
          {live_update && (
            <Row gutter={10} className="mb-1">
              {(state.type === "UTM" || state.type === "GK") && (
                <>
                  <Col className="flex align-items-center flex-1">
                    <label htmlFor="x">X</label>
                    <Input
                      value={state.x}
                      onChange={(e) => {
                        if (state.type === "UTM") {
                          const { lat, lon } = utmToLatLon({
                            x: e.target.value,
                            y: state.y?.toString() || "0",
                          });
                          setState((s) => ({ ...s, lat, lng: lon }));
                        }
                        if (state.type === "GK") {
                          const { lat, lon } = gkToLatLon({
                            x: Number(e.target.value),
                            y: state.y?.toString() || "0",
                          });
                          setState((s) => ({ ...s, lat, lng: lon }));
                        }
                        setState((s) => ({ ...s, x: e.target.value }));
                      }}
                      placeholder={"x"}
                      className="mx-1 flex-1"
                    />
                  </Col>
                  <Col className="flex align-items-center flex-1">
                    <label htmlFor="y">Y</label>
                    <Input
                      value={state.y}
                      onChange={(e) => {
                        if (state.type === "UTM") {
                          const { lat, lon } = utmToLatLon({
                            x: state.x?.toString() || "0",
                            y: e.target.value,
                          });
                          setState((s) => ({ ...s, lat, lng: lon }));
                        }
                        if (state.type === "GK") {
                          const { lat, lon } = gkToLatLon({
                            x: state.x?.toString() || "0",
                            y: e.target.value,
                          });
                          setState((s) => ({ ...s, lat, lng: lon }));
                        }
                        setState((s) => ({ ...s, y: e.target.value }));
                      }}
                      placeholder={"y"}
                      className="mx-1 flex-1"
                    />
                  </Col>
                </>
              )}
              {state.type === "WGS84" && (
                <>
                  <Col className="flex align-items-center flex-1">
                    <label htmlFor="lat">{t("lat")}</label>
                    <Input
                      value={state.lat}
                      onChange={(e) => {
                        setState((s) => ({ ...s, lat: e.target.value }));
                      }}
                      placeholder={t("lat")}
                      className="mx-1 flex-1"
                    />
                  </Col>
                  <Col className="flex align-items-center flex-1">
                    <label htmlFor="lng">{t("lng")}</label>
                    <Input
                      value={state.lng}
                      onChange={(e) => {
                        setState((s) => ({ ...s, lng: e.target.value }));
                      }}
                      placeholder={t("lng")}
                      className="mx-1 flex-1"
                    />
                  </Col>
                </>
              )}
              {state.type === "WGS84(minutes)" && (
                <>
                  <Col className="flex align-items-center flex-1">
                    <span>{t("lat")}: </span>
                    <Input
                      value={state.dmObj.lat.deg}
                      onChange={(e) => {
                        const dmsObjCopy = { ...state.dmObj };
                        dmsObjCopy.lat.deg = e.target.value;
                        setState((s) => ({ ...s, dmObj: dmsObjCopy }));
                      }}
                      placeholder={t("lat")}
                      className="mx-0 flex-1"
                    />
                    <span>&deg;</span>
                    <Input
                      value={state.dmObj.lat.min}
                      onChange={(e) => {
                        const dmsObjCopy = { ...state.dmObj };
                        dmsObjCopy.lat.min = e.target.value;
                        setState((s) => ({ ...s, dmObj: dmsObjCopy }));
                      }}
                      placeholder={t("lat")}
                      className="mx-0 flex-1"
                    />
                    <span>'</span>
                    <Button disabled variant="info">
                      {getLatDegree(Number(state.dmObj.lat.deg))}
                    </Button>
                  </Col>
                  <Col className="flex align-items-center flex-1">
                    <span>{t("lat")}: </span>
                    <Input
                      value={state.dmObj.lng.deg}
                      onChange={(e) => {
                        const dmsObjCopy = { ...state.dmObj };
                        dmsObjCopy.lng.deg = e.target.value;
                        setState((s) => ({ ...s, dmObj: dmsObjCopy }));
                      }}
                      placeholder={t("lng")}
                      className="mx-0 flex-1"
                    />
                    <span>&deg;</span>
                    <Input
                      value={state.dmObj.lng.min}
                      onChange={(e) => {
                        const dmsObjCopy = { ...state.dmObj };
                        dmsObjCopy.lng.min = e.target.value;
                        setState((s) => ({ ...s, dmObj: dmsObjCopy }));
                      }}
                      placeholder={t("lng")}
                      className="mx-0 flex-1"
                    />
                    <span>'</span>
                    <Button disabled variant="info">
                      {getLngDegree(Number(state.dmObj.lng.deg))}
                    </Button>
                  </Col>
                </>
              )}
              {state.type === "WGS84(minutes, seconds)" && (
                <>
                  <Col className="flex align-items-center flex-1">
                    <span>{t("lat")}: </span>
                    <Input
                      value={state.dmsObj.lat.deg}
                      onChange={(e) => {
                        const dmsObjCopy = { ...state.dmsObj };
                        dmsObjCopy.lat.deg = e.target.value;
                        setState((s) => ({ ...s, dmsObj: dmsObjCopy }));
                      }}
                      placeholder={t("lat")}
                      className="mx-0 flex-1"
                    />
                    <span>&deg;</span>
                    <Input
                      value={state.dmsObj.lat.min}
                      onChange={(e) => {
                        const dmsObjCopy = { ...state.dmsObj };
                        dmsObjCopy.lat.min = e.target.value;
                        setState((s) => ({ ...s, dmsObj: dmsObjCopy }));
                      }}
                      placeholder={"min"}
                      className="mx-0 flex-1"
                    />
                    <span>'</span>
                    <Input
                      value={state.dmsObj.lat.sec}
                      onChange={(e) => {
                        const dmsObjCopy = { ...state.dmsObj };
                        dmsObjCopy.lat.sec = e.target.value;
                        setState((s) => ({ ...s, dmsObj: dmsObjCopy }));
                      }}
                      placeholder={"sec"}
                      className="mx-0 flex-1"
                    />
                    <span>"</span>
                    <Button disabled variant="info">
                      {getLatDegree(Number(state.dmsObj.lat.deg))}
                    </Button>
                  </Col>
                  <Col className="flex align-items-center flex-1">
                    <span>{t("lng")}: </span>
                    <Input
                      value={state.dmsObj.lng.deg}
                      onChange={(e) => {
                        const dmsObjCopy = { ...state.dmsObj };
                        dmsObjCopy.lng.deg = e.target.value;
                        setState((s) => ({ ...s, dmsObj: dmsObjCopy }));
                      }}
                      placeholder={t("lng")}
                      className="mx-0 flex-1"
                    />
                    <span>&deg;</span>
                    <Input
                      value={state.dmsObj.lng.min}
                      onChange={(e) => {
                        const dmsObjCopy = { ...state.dmsObj };
                        dmsObjCopy.lng.min = e.target.value;
                        setState((s) => ({ ...s, dmsObj: dmsObjCopy }));
                      }}
                      placeholder={"min"}
                      className="mx-0 flex-1"
                    />
                    <span>'</span>
                    <Input
                      value={state.dmsObj.lng.sec}
                      onChange={(e) => {
                        const dmsObjCopy = { ...state.dmsObj };
                        dmsObjCopy.lng.sec = e.target.value;
                        setState((s) => ({ ...s, dmsObj: dmsObjCopy }));
                      }}
                      placeholder={"sec"}
                      className="mx-0 flex-1"
                    />
                    <span>"</span>
                    <Button disabled variant="info">
                      {getLngDegree(Number(state.dmsObj.lng.deg))}
                    </Button>
                  </Col>
                </>
              )}
              {state.type === "MGRS" && (
                <>
                  <Col className="flex align-items-center flex-1">
                    <label htmlFor="mgrs">mgrs</label>
                    <Input
                      value={state.mgrs}
                      onChange={(e) => {
                        try {
                          setState((s) => ({ ...s, mgrs: e.target.value }));
                          const { lat, lon } = mgrsToLatLon({
                            mgrs: e.target.value,
                          });
                          setState((s) => ({ ...s, lat, lng: lon }));
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      placeholder={"mgrs"}
                      className="mx-1 flex-1"
                    />
                  </Col>
                </>
              )}
              <Col className="flex align-items-center flex-1">
                <label htmlFor="type">{t("type")}</label>
                <Select
                  placeholder={t("type")}
                  className="mx-1 flex-1"
                  value={state.type}
                  onChange={(val) => {
                    setState((s) => ({
                      ...s,
                      type: val,
                    }));
                  }}
                >
                  <Select.Option value="UTM">{t("utm")}</Select.Option>
                  <Select.Option value="WGS84">{t("WGS84")}</Select.Option>
                  <Select.Option value="WGS84(minutes)">
                    {t("WGS84(minutes)")}
                  </Select.Option>
                  <Select.Option value="WGS84(minutes, seconds)">
                    {t("WGS84(minutes, seconds)")}
                  </Select.Option>
                  <Select.Option value="MGRS">{t("MGRS")}</Select.Option>
                  <Select.Option value="GK">{t("gaussKrüger")}</Select.Option>
                  <Select.Option value="GK">Gauss Krüger</Select.Option>
                </Select>
              </Col>
              <Space>
                <Button loading={state.loading} onClick={save}>
                  {t("save")}
                </Button>
                {printable && (
                  <Button className="mx-1" onClick={screenshot}>
                    {t("print")}
                  </Button>
                )}

                {sharable && (
                  <Space>
                    <Button
                      loading={state.share_loading}
                      onClick={backEndShare}
                    >
                      {t("share")}
                    </Button>

                    {share_array.includes("Whatsapp") && (
                      <WhatsappShareButton
                        // url={`https://www.google.com/maps/@${state.lat},${state.lng},${defaultZoom}z`}
                        url={`https://maps.google.com/?q=${state.lat},${state.lng}`}
                      >
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                    )}
                    {share_array.includes("Facebook") && (
                      <FacebookShareButton
                        // url={`https://www.google.com/maps/@${state.lat},${state.lng},${defaultZoom}z`}
                        url={`https://maps.google.com/?q=${state.lat},${state.lng}`}
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                    )}
                    {share_array.includes("Twitter") && (
                      <TwitterShareButton
                        // url={`https://www.google.com/maps/@${state.lat},${state.lng},${defaultZoom}z`}
                        url={`https://maps.google.com/?q=${state.lat},${state.lng}`}
                      >
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>
                    )}
                    {share_array.includes("Telegram") && (
                      <TelegramShareButton
                        // url={`https://www.google.com/maps/@${state.lat},${state.lng},${defaultZoom}z`}
                        url={`https://maps.google.com/?q=${state.lat},${state.lng}`}
                      >
                        <TelegramIcon size={32} round />
                      </TelegramShareButton>
                    )}
                    {share_array.includes("LinkedIn") && (
                      <LinkedinShareButton
                        // url={`https://www.google.com/maps/@${state.lat},${state.lng},${defaultZoom}z`}
                        url={`https://maps.google.com/?q=${state.lat},${state.lng}`}
                      >
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>
                    )}
                    {share_array.includes("email") && (
                      <EmailShareButton
                        // url={`https://www.google.com/maps/@${state.lat},${state.lng},${defaultZoom}z`}
                        url={`https://maps.google.com/?q=${state.lat},${state.lng}`}
                      >
                        <EmailIcon size={32} round />
                      </EmailShareButton>
                    )}
                  </Space>
                )}
              </Space>
            </Row>
          )}
          <div style={{ height: `${height}px`, width: "100%" }} ref={ref}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: google_maps_api_key as string,
                version: "0.34.0",
              }}
              defaultCenter={{
                lat: parseFloat(state.lat),
                lng: parseFloat(state.lng),
              }}
              defaultZoom={defaultZoom}
              onClick={_onClick}
              onGoogleApiLoaded={({ map, maps }) => {
                mapRef.current = map;
                mapsRef.current = maps;
                setMarker(state.lat || "0", state.lng || "0");
              }}
              onChange={({ center }) => getPoints(center)}
              options={{
                mapTypeControl: true,
                mapTypeControlOptions: [
                  "roadmap",
                  "satellite",
                  "hybrid",
                  "terrain",
                ],
                fullscreenControl: true,
              }}
            />
          </div>
        </div>
      )}
    </Card>
  );
};
