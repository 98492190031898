import { CHANGE_LANGUAGE, CHANGE_MODE, LOGOUT, SIDE_MENU } from "@/queries";
import { useMutation, useQuery } from "@apollo/client";
import { Icon } from "@components/dynamic/Icon";
import { useServiceWorker } from "@hooks/useServiceWorker";
import { IAction } from "@interfaces/action.interface";
import { IScope } from "@interfaces/scope.interface";
import { IUser } from "@interfaces/user.interface";
import { AuthActions } from "@store/reducers/auth.reducer";
import {
  Avatar,
  Col,
  Dropdown,
  Layout,
  List,
  Menu,
  Row,
  Segmented,
  Select,
  Skeleton,
  Spin,
} from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { CaretDown, Moon, Sun } from "phosphor-react";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import AllComponents from "@/components";
import { Socket } from "@/components/Socket";
import Button from "@/components/ui/buttons/Button";
import { useLogoSrc } from "@/hooks/useLogoSrc";
import { ISocketData } from "@/interfaces/socket.interface";
import { ConfigActions } from "@/store/reducers/config.reducer";
import { SideMenuActions } from "@/store/reducers/side-menu.reducer";
import { Action } from "@components/dynamic/Action";
import { ThemeActions, themeSelector } from "@store/reducers/theme.reducer";
import classNames from "classnames";
import { Page } from "./Page";
import { Test } from "./Text";

const { Header, Content, Sider } = Layout;

interface ICPanel {}

const CPanel: React.FC<PropsWithChildren<ICPanel>> = ({ children }) => {
  const {
    loading: menuLoading,
    error: menuError,
    data: menu,
  } = useQuery(SIDE_MENU, { variables: {} });

  const [changeLanguageMutation, {}] = useMutation(CHANGE_LANGUAGE);
  const [changeModeMutation, {}] = useMutation(CHANGE_MODE);

  const theme = useSelector(themeSelector).theme;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { switcher, themes, currentTheme, status } = useThemeSwitcher();

  const gui_data = (menu?.getGUI?.gui_data || []) as IScope[];
  const user = (menu?.getGUI?.user || []) as IUser;
  const socket_data = (menu?.getGUI?.socket_data || []) as ISocketData[];

  const general_actions = (menu?.getGUI?.general_actions || []) as IAction[];

  const lang = gui_data.find((e) => e.name === "lang");
  const modes = gui_data.find((e) => e.name === "modes")?.value === "1";
  const default_lang = gui_data.find((e) => e.name === "default_lang");
  const enableLang = lang?.value === "1";
  const langs = lang?.array || [];

  const userLang = user.preferences?.find((e) => e.key === "lang")?.value || "";
  const default_page = menu?.getGUI?.default_page;
  const site_name = menu?.getGUI?.site_name;
  const has_page_side_menu = menu?.getGUI?.has_page_side_menu;
  const userTheme =
    user.preferences?.find((e) => e.key === "mode")?.value || "";
  const { setToken } = useServiceWorker();

  useEffect(() => {
    if (gui_data && gui_data.length) {
      const cfg =
        gui_data?.find((item: any) => item.name === "config")?.array || [];
      const api_key = cfg?.find(
        (item: any) => item.name === "maps_api_key"
      )?.value;
      if (api_key) {
        dispatch(ConfigActions.setGoogleMapsKey({ key: api_key }));
      }
    }
  }, [gui_data]);

  useEffect(() => {
    dispatch(SideMenuActions.hasSideMenu({ hasSideMenu: has_page_side_menu }));
  }, [has_page_side_menu]);

  const [state, setState] = useState({
    loggingout: false,
    initUserData: false,
  });

  const [logOut] = useMutation(LOGOUT);

  const logout = () => {
    setState((s) => ({ ...s, loggingout: true }));

    logOut()
      .then(() => {
        setState((s) => ({ ...s, loggingout: false }));
        setToken?.("");
        dispatch(AuthActions.logout());
      })
      .catch(() => {
        setState((s) => ({ ...s, loggingout: false }));
        setToken?.("");
        dispatch(AuthActions.logout());
      });

    // setTimeout(() => {
    //   setState((s) => ({ ...s, loggingout: false }));
    //   setToken?.("");
    //   dispatch(AuthActions.logout());
    // });
  };
  const goTo = (route: string) => navigate(route);

  const changeLang = (lang: string) => {
    i18n.changeLanguage(lang);

    changeLanguageMutation({ variables: { user_id: user.id, lang } }).then(
      () => {}
    );
    // document.location.reload();
  };

  useEffect(() => {
    const dir = i18n.language === "ar" ? "rtl" : "ltr";
    document.body.dir = dir;
  }, [i18n.language]);

  const changeTheme = (_theme: SegmentedValue) => {
    dispatch(ThemeActions.setTheme(_theme.toString()));
    switcher({
      theme: _theme.toString() === "dark" ? themes.light : themes.dark,
    });

    changeModeMutation({
      variables: { user_id: user.id, mode: _theme.toString() },
    }).then(() => {});
  };

  useEffect(() => {
    if (theme === "light") {
      document.body.classList.remove("dark");
      document.body.classList.add("light");
    } else {
      document.body.classList.remove("light");
      document.body.classList.add("dark");
    }
  }, [theme]);

  useEffect(() => {
    if (site_name) {
      document.title = site_name;
    }
  }, [site_name]);
  useEffect(() => {
    if (gui_data && gui_data.length) {
      if ((userLang || userTheme) && !state.initUserData) {
        if (userLang && userLang !== i18n.language) {
          changeLang(userLang);
        } else {
          changeLang(
            localStorage.getItem("i18nextLng") || default_lang?.value || "en"
          );
        }

        if (userTheme && userTheme !== currentTheme) {
          changeTheme(userTheme);
        }
        setState((s) => ({ ...s, initUserData: true }));
      }
    }
  }, [gui_data, userLang, default_lang]);

  const [collapsed, setCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const logoSrc = useLogoSrc();

  return (
    <Layout className="layout">
      <Header className="header">
        <Row align="middle">
          <Button
            type="link"
            className="menu-btn"
            onClick={() => setCollapsed(!collapsed)}
          >
            <Icon name="list" />
          </Button>
          <Col flex={"auto"}>
            <a className="logo" href="/">
              <img src={logoSrc} />
            </a>
          </Col>
          {/* <Col flex={"auto"}>
            <Input
              className="search-input"
              bordered={false}
              placeholder={t("search")}
              prefix={<MagnifyingGlass />}
            />
          </Col> */}

          <Col style={{ display: "flex", gap: "16px", alignItems: "center" }}>
            {general_actions.map((action, index) => {
              return (
                <Action
                  key={`general-actions-page-${index}`}
                  item={undefined}
                  action={action}
                  // type="general_header"
                />
              );
            })}
          </Col>
        </Row>
      </Header>
      <Layout>
        <Sider
          collapsed={collapsed}
          breakpoint="lg"
          onBreakpoint={(broken) => {
            if (broken) {
              setCollapsed(true);
            }
            setIsMobile(broken);
          }}
          collapsedWidth={isMobile ? 0 : 80}
          width={240}
          className="site-layout-background sidebar"
          trigger={null}
        >
          {menuLoading && (
            <List
              itemLayout="vertical"
              size="large"
              dataSource={[1, 2, 3, 4, 5]}
              renderItem={(item, index) => (
                <List.Item key={`skelaton-${index}`}>
                  <Skeleton loading={true} active avatar>
                    <List.Item.Meta
                      avatar={<Avatar src={""} />}
                      title={<a>{""}</a>}
                      description={""}
                    />
                    {null}
                  </Skeleton>
                </List.Item>
              )}
            />
          )}
          <Menu hidden={menuLoading} mode="inline">
            {menu?.getGUI?.modules?.map((module: any, index: number) => {
              return (
                <Menu.ItemGroup
                  key={`${module?.name}-${index}`}
                  title={<span>{module?.label}</span>}
                >
                  {module?.menu?.map((subRoute: any, index2: number) => {
                    return subRoute?.childs?.length > 0 ? (
                      <Menu.SubMenu
                        key={`${subRoute.name}-${index}-${index2}-i`}
                        icon={<Icon name={subRoute.name} />}
                        title={subRoute.label}
                      >
                        {subRoute?.childs?.map((child: any, index3: number) => {
                          return (
                            <Menu.Item
                              onClick={() => {
                                goTo(`/page/${child.name}`);
                                if (isMobile) setCollapsed(true);
                              }}
                              key={`router-child-index-${index}-${index3}`}
                              icon={<Icon name={child?.icon} />}
                            >
                              {child?.label}
                            </Menu.Item>
                          );
                        })}
                      </Menu.SubMenu>
                    ) : (
                      <Menu.Item
                        icon={<Icon name={subRoute?.icon} />}
                        key={`${subRoute.name}-${index}-${index2}-i`}
                        onClick={() => {
                          goTo(`/page/${subRoute.name}`);
                          if (isMobile) setCollapsed(true);
                        }}
                        className={
                          `/page/${subRoute.route}`?.includes(`${pathname}`)
                            ? `ant-menu-item-selected`
                            : ""
                        }
                      >
                        {subRoute.label}
                      </Menu.Item>
                    );
                  })}
                </Menu.ItemGroup>
              );
            })}
          </Menu>

          <div className="user-area">
            <Dropdown
              className="user-dropdown"
              overlay={
                <Menu>
                  <Menu.Item danger key={`log out`} onClick={logout}>
                    {state.loggingout && <Spin />} {t("logout")}
                  </Menu.Item>
                </Menu>
              }
              placement="topLeft"
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <Avatar
                  shape="square"
                  size="large"
                  src="https://joeschmoe.io/api/v1/random"
                  style={{ backgroundColor: "gray" }}
                />
                <div>
                  <h4>{user?.fullname}</h4>
                  <p>{user?.email}</p>
                </div>
                <CaretDown />
              </a>
            </Dropdown>
            <Row gutter={[16, 16]}>
              {modes && (
                <Col span={12}>
                  <Segmented
                    block
                    size="small"
                    value={theme + ""}
                    onChange={changeTheme}
                    options={[
                      {
                        value: "light",
                        icon: <Sun />,
                      },
                      {
                        value: "dark",
                        icon: <Moon />,
                      },
                    ]}
                  />
                </Col>
              )}
              {enableLang && langs.length > 1 && (
                <Col span={12}>
                  <Select
                    className="w-100"
                    size="small"
                    onChange={changeLang}
                    placement="topLeft"
                    value={i18n.language}
                  >
                    {langs.map((lang, index) => (
                      <Select.Option
                        key={`lang-key-${lang.value}-${index}`}
                        value={lang.value}
                      >
                        {lang.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              )}
            </Row>
          </div>
        </Sider>

        <Layout
          className={classNames("main-content", collapsed && "menu-collapsed")}
        >
          {!menuLoading && (
            <Content className="site-layout-background page-content">
              <Routes>
                <Route path="/page/:name" element={<Page />} />
                <Route path="/all" element={<AllComponents />} />
                <Route path="/test" element={<Test />} />

                {default_page && (
                  <Route
                    path="*"
                    element={<Navigate to={`/page/${default_page}`} replace />}
                  />
                )}
              </Routes>
            </Content>
          )}
        </Layout>
      </Layout>
      <Socket socket_data={socket_data} />
    </Layout>
  );
};

export default CPanel;
