import { useRedirect } from "@/hooks/useRedirect";
import { FormActions } from "@/store/reducers/form.reducer";
import { Card } from "@components/dynamic/Card";
import Button from "@components/ui/buttons/Button";
import { useAction } from "@hooks/useAction";
import { IBlock } from "@interfaces/block.interface";
import { IScope } from "@interfaces/scope.interface";
import { Col, Form, FormInstance, Input, Row, Space } from "antd";
import { Rule } from "antd/lib/form";
import classNames from "classnames";
import { Check, NotePencil, X } from "phosphor-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { IMaskInput, IMask } from "react-imask";
import { useDispatch } from "react-redux";

const RegexParser = require("regex-parser");

interface IProps {
  block: IBlock;
  scope: IScope[];
  loading: boolean;
  form: FormInstance<any>;

  formState: any;
  setFormState: any;
  editInline?: boolean;

  onChange?: (value: any, variable: string, ref?: any) => void;
  visible?: boolean;
  disabled: string;
}

export const FieldInput: React.FC<IProps> = ({
  block,
  scope = [],
  form,
  setFormState,
  formState,
  onChange,
  loading,
  editInline = false,
  visible,
  disabled,
  ...props
}) => {
  const config: IScope[] = block?.config || [];

  const variable = config.find((e) => e.name === "variable")?.value || "";
  const placeholder = config.find((e) => e.name === "placeholder")?.value || "";
  const placeholderChar =
    config.find((e) => e.name === "placeholder_char")?.value || "_";
  const required = config.find((e) => e.name === "required")?.value === "1";
  const pattern = config.find((e) => e.name === "pattern")?.value;
  const mask = config.find((e) => e.name === "mask")?.value || "";
  const mask_def = config.find((e) => e.name === "mask_def")?.array || [];
  const validation_message = config.find(
    (e) => e.name === "validation_message"
  )?.value;

  const graphql = config.find((e) => e.name === "graphql")?.value;
  const expanded = config.find((e) => e.name === "expanded")?.value === "1";
  const expandable = config.find((e) => e.name === "expandable")?.value === "1";
  const password = config.find((e) => e.name === "password")?.value === "1";
  const can_do = config.find((e) => e.name === "can_do")?.value === "1";
  const auto_save = config.find((e) => e.name === "auto_save")?.value || "";
  const query = config.find((e) => e.name === "query")?.value;
  const timeout = config.find((e) => e.name === "timeout")?.value
    ? parseInt(
        config.find((e) => e.name === "timeout")?.value || ("0" as string)
      )
    : 1000;

  const autoFocus = config.find((e) => e.name === "autoFocus")?.value === "1";

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const dispatch = useDispatch();
  const { doMutateQraphQl, replaceFromScope } = useAction();

  const { checkRedirect } = useRedirect();

  const [state, setState] = useState({
    loading: false,
    isValid: false,
    editMode: false,
    value: block?.body || "",
    editedValue: block?.body || "",

    maskInitedValue: "",
  });

  const { rules } = useMemo(() => {
    const rules: Rule[] = [];

    if (pattern) {
      rules.push({
        pattern: new RegExp(RegexParser(pattern)),
        message: validation_message,
      });
    }
    if (required) {
      rules.push({
        required: required,
        message: `${block?.title || block?.label} is required.`,
      });
    }

    return { rules };
  }, [required, pattern]);

  const CardComponent = (
    <Card
      body={password ? "" : state.value}
      title={block?.title}
      expanded={expanded}
      classes={block?.classes}
      expandable={expandable}
      visible={visible}
      config={config}
      editBodyButton={
        can_do ? (
          <Button
            key="card-edit-action-edit-inline"
            // type="button"
            loading={state.loading}
            variant="primary"
            // size="small"
            onClick={() => setState((s) => ({ ...s, editMode: true }))}
            icon={<NotePencil />}
          />
        ) : undefined
      }
    />
  );

  const save = () => {
    let query = "";

    if (required && !state.editedValue) return;

    if (pattern) {
      const expression = new RegExp(RegexParser(pattern));

      const isMatch = expression.test(state.editedValue);
      if (!isMatch) return;
    }

    query = replaceFromScope({
      scope: [{ name: variable, value: state.editedValue }],
      str: graphql + "",
      withQuate: false,
    });

    setState((s) => ({ ...s, loading: true }));

    doMutateQraphQl(query)
      .then(() => {
        console.log("asdasdasd");
        checkRedirect(config);

        setState((s) => ({
          ...s,
          loading: false,
          editMode: false,
          value: password ? "" : s.editedValue,
          editedValue: password ? "" : s.editedValue,
        }));

        onChange && onChange(state.editedValue, variable);
      })
      .catch((e) => {
        setState((s) => ({ ...s, loading: false, editMode: false }));
      });
  };
  const cancel = () => {
    setState((s) => ({ ...s, editMode: false, editedValue: s.value }));
  };

  const Required = () => {
    return (
      !state.editedValue && (
        <div className="ant-form-item-explain ">
          <div role="alert" className="ant-form-item-explain-error">
            {variable} is required.
          </div>
        </div>
      )
    );
  };
  const Pattern = () => {
    if (pattern) {
      const expression = new RegExp(RegexParser(pattern));

      const isMatch = expression.test(state.editedValue);

      return (
        !isMatch && (
          <div className="ant-form-item-explain ">
            <div role="alert" className="ant-form-item-explain-error">
              {validation_message}
            </div>
          </div>
        )
      );
    } else return null;
  };

  const definitions = useMemo(() => {
    let defs: {
      [key: string]: RegExp;
    } = {};

    mask_def.map((e) => {
      defs = {
        ...defs,
        [e.name || ""]: new RegExp(RegexParser(e.value || "")),
      };
    });

    return defs;
  }, [mask_def]);

  const ref = useRef<any>();

  useEffect(() => {
    if (ref.current?.maskRef?.unmaskedValue) {
      setState((s) => ({
        ...s,
        maskInitedValue: ref.current?.maskRef?.unmaskedValue,
      }));
    }
  }, [ref.current]);

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        ref.current?.focus();
      }, 500);
    }
  }, [autoFocus]);

  const onFieldChange = (val: string) => {
    if (auto_save && auto_save === "on_change") {
      if (required && !val) return;

      if (timer) {
        clearTimeout(timer);
      }

      const newTimer = setTimeout(async () => {
        let q = "";

        if (pattern) {
          const expression = new RegExp(RegexParser(pattern));

          const isMatch = expression.test(val);
          if (!isMatch) return;
        }

        q = replaceFromScope({
          scope: [{ name: variable, value: val }],
          str: query + "",
          withQuate: false,
        });

        doMutateQraphQl(q)
          .then(() => {
            checkRedirect(config);
          })
          .catch((err) => {
            console.log(err);
          });
      }, timeout);
      setTimer(newTimer);
    }
  };

  const onFieldBlur = (val: string) => {
    if (auto_save && auto_save === "on_blur") {
      if (required && !val) return;
      let q = "";

      if (pattern) {
        const expression = new RegExp(RegexParser(pattern));

        const isMatch = expression.test(val);
        if (!isMatch) return;
      }

      q = replaceFromScope({
        scope: [{ name: variable, value: val }],
        str: query + "",
        withQuate: false,
      });

      doMutateQraphQl(q)
        .then(() => {
          checkRedirect(config);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return editInline ? (
    state.editMode ? (
      <Card
        body={""}
        title={block?.title}
        expanded={expanded}
        classes={block?.classes}
        expandable={expandable}
        visible={visible}
        config={config}
      >
        <Row wrap={false} gutter={[16, 16]}>
          <Col flex="auto">
            {!mask ? (
              <>
                <Input
                  id={block?.name}
                  placeholder={placeholder}
                  disabled={disabled === "1" || state.loading}
                  value={state.editedValue}
                  type={password ? "password" : "text"}
                  ref={ref}
                  onChange={(event) => {
                    setState((s) => ({
                      ...s,
                      editedValue: event.target.value,
                    }));
                  }}
                  onFocus={() => {
                    dispatch(FormActions.saveInput({ lastInput: ref.current }));
                  }}
                  {...props}
                />
              </>
            ) : (
              <>
                <IMaskInput
                  id={block?.name}
                  ref={ref}
                  placeholderChar={placeholderChar}
                  mask={mask}
                  definitions={definitions}
                  lazy={false}
                  type={password ? "password" : "text"}
                  className="ant-input"
                  placeholder={placeholder}
                  disabled={disabled === "1" || state.loading}
                  {...props}
                  onComplete={(event, { value, unmaskedValue }) => {
                    setState((s) => ({ ...s, editedValue: unmaskedValue }));
                  }}
                  onFocus={() => {
                    dispatch(FormActions.saveInput({ lastInput: ref.current }));
                  }}
                  onAccept={(e, { unmaskedValue }) => {
                    if (state.maskInitedValue === unmaskedValue) {
                      setState((s) => ({ ...s, editedValue: "" }));
                    }
                  }}
                />
              </>
            )}
          </Col>
          <Col flex="none">
            <Space direction="horizontal" style={{}}>
              <Button
                variant="primary"
                type="primary"
                icon={<Check />}
                // width={10}
                onClick={save}
                loading={state.loading}
              ></Button>
              <Button
                variant="danger"
                type="primary"
                icon={<X />}
                // width={10}
                disabled={state.loading}
                onClick={cancel}
              ></Button>
            </Space>
          </Col>
        </Row>

        {Required()}
        {Pattern()}
      </Card>
    ) : (
      CardComponent
    )
  ) : (
    <Form.Item
      label={block?.title || block?.label}
      name={variable}
      rules={rules}
      className={classNames(visible === false && "d-none")}
    >
      {!!mask ? (
        <IMaskInput
          id={block?.name}
          ref={ref}
          className="ant-input"
          placeholderChar={placeholderChar}
          mask={mask}
          definitions={definitions}
          lazy={false}
          type={password ? "password" : "text"}
          // mask={"a[######]{@}a[######].aa[#]"}
          // definitions={{
          //   // '#': /[1-6]/
          //   // '#':
          //   // all chars for email chars
          //   '#': /[a-zA-Z0-9_]/,
          // }}

          placeholder={placeholder}
          disabled={disabled === "1" || loading}
          {...props}
          onFocus={() => {
            dispatch(FormActions.saveInput({ lastInput: ref.current }));
          }}
          onComplete={(event, { value, unmaskedValue }) => {
            form.setFieldsValue({ [variable]: unmaskedValue });
            setFormState((s: any) => ({
              ...s,
              [variable]: unmaskedValue,
            }));

            onFieldChange?.(unmaskedValue);

            onChange && onChange(unmaskedValue, variable);
          }}
          onAccept={(e, { unmaskedValue }) => {
            form.setFieldsValue({ [variable]: unmaskedValue });

            if (state.maskInitedValue === unmaskedValue) {
              setFormState((s: any) => ({
                ...s,
                [variable]: "",
              }));

              onChange && onChange("", variable);
            }
          }}
        />
      ) : (
        <Input
          id={block?.name}
          type={password ? "password" : "text"}
          ref={ref}
          placeholder={placeholder}
          disabled={disabled === "1" || loading}
          onChange={({ target: { value } }) => {
            form.setFieldsValue({ [variable]: value });
            setFormState((s: any) => ({
              ...s,
              [variable]: value,
            }));

            onFieldChange?.(value);

            onChange && onChange(value, variable, ref);
          }}
          onBlur={({ target: { value } }) => {
            onFieldBlur?.(value);
          }}
          {...props}
          onFocus={() => {
            dispatch(FormActions.saveInput({ lastInput: ref.current }));
          }}
        />
      )}
    </Form.Item>
  );
};
